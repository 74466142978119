/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */

import constants from '../../constants/Constants';
import {
  FacilitatorPageWithTemplate,
  LearnerPageWithTemplate,
  OrganizationSettings,
  PageWithTemplate,
  SanityContent,
  SanityGroupSettings,
  SanitySettings,
} from '../../pageTemplates/types/courseTypes';
import { logError } from '../../services/errorHandling';
import learnerTemplateTypes from '../groupRevivr/learner/templates/types/learnerTemplateTypes';
import {
  API,
  FetchProject,
  FetchSettings,
  fetchLanguages,
  fetchLearnerTeamSplitPage,
  fetchNavigation,
  fetchSettings,
} from './SanityFetch';
import { findTargetLearnerNavigation } from './SanityServiceHelpers';
import {
  mapCommonNavigation,
  mapFacilitatorPageWithTemplate,
  mapLearnerNavigation,
  mapPageWithTemplate,
} from './SanityServiceMapper';
import { getLocaleCodeForSanity } from './localeHelper';
import urlFor from './sanityAssetUrl';
import {
  GroupNavigation,
  LearnerNavigation,
  LocalizedGroupNavigation,
  LocalizedGroupProjectNavigation,
  isLocalizedGroupNavigation,
} from './sanityTypes';
import { CommonNavigation } from './sanityTypes/navigationTypes';

declare global {
  interface Window {
    sanity: {
      sanityContent: SanityContent | undefined;
    };
  }
}

window.sanity = { sanityContent: undefined };

const fetchData = async <T,>(fetchProp: FetchSettings<T>): Promise<T> => {
  try {
    const res = await fetchProp.fetchAsync();
    return res;
  } catch (exception: unknown) {
    logError(exception);
    throw exception;
  }
};

const getSettings = (
  fetchSettingsProp: API<OrganizationSettings>,
): Promise<OrganizationSettings> =>
  fetchData<OrganizationSettings>(fetchSettingsProp);

const getNavigation = (
  fetchNavigationProp: API<SanityContent>,
): Promise<SanityContent> => fetchData<SanityContent>(fetchNavigationProp);

const getLearnerTeamSplitPage = (
  fetchLearnerTeamSplitPageProp: API<LearnerNavigation | LearnerNavigation[]>,
): Promise<LearnerNavigation | LearnerNavigation[]> => {
  return fetchData<LearnerNavigation | LearnerNavigation[]>(
    fetchLearnerTeamSplitPageProp,
  );
};
export function isCommonNavigation(
  navigation: GroupNavigation | LocalizedGroupNavigation | CommonNavigation,
): navigation is CommonNavigation {
  return (navigation as CommonNavigation)?.template?._type !== undefined;
}

const getSupportedLanguages = (
  fetchSupportedLanguagesProp: API<string[]>,
): Promise<string[]> => fetchData<string[]>(fetchSupportedLanguagesProp);

const getLearnerNavigationWithRoleSplitTemplates = async (
  groupNavigationArray: Array<
    GroupNavigation | LocalizedGroupNavigation | CommonNavigation
  >,
) => {
  const idArray = groupNavigationArray
    .filter((groupNavigation) => {
      if (
        isCommonNavigation(groupNavigation) ||
        !groupNavigation.learnerNavigation
      ) {
        return false;
      }
      if (isLocalizedGroupNavigation(groupNavigation)) {
        return (
          groupNavigation.learnerNavigation.translation[0].translations[0].value
            .template === learnerTemplateTypes.LearnerRoleSplitTemplate
        );
      }
      return (
        groupNavigation.learnerNavigation.template ===
        learnerTemplateTypes.LearnerRoleSplitTemplate
      );
    })
    .map((groupNavigation: GroupNavigation | LocalizedGroupNavigation) => {
      return isLocalizedGroupNavigation(groupNavigation)
        ? groupNavigation.learnerNavigation.translation[0].translations[0].value
            ._id
        : groupNavigation.learnerNavigation._id;
    });

  // Fetching all learnerNavigation with learnerRoleSplitTemplates in one query, using the array of ids.
  const learnerNavigationWithRoleSplitTemplates = await getLearnerTeamSplitPage(
    fetchLearnerTeamSplitPage(idArray),
  );
  return learnerNavigationWithRoleSplitTemplates;
};

const mapSanityToProject = async (res, navigation): Promise<SanityContent> => {
  let mappedCourse:
    | PageWithTemplate[]
    | FacilitatorPageWithTemplate[]
    | LearnerPageWithTemplate[] = [];
  let mappedSettings: SanitySettings | SanityGroupSettings =
    {} as SanityGroupSettings;
  const mappedSupportedLanguages: string[] = [];
  try {
    const commonSettings = {
      brandLogoAlt: res.organization.brandLogoAlt,
      brandLogoUrl: urlFor(res.organization.brandLogo),
      fadeInDuration:
        res.groupProjectSettings?.fadeInDuration ??
        res.projectSettings?.fadeInDuration ??
        0,
      enableCameraFilter:
        res.groupProjectSettings?.enableCameraFilter ??
        res.projectSettings?.enableCameraFilter ??
        false,
      organizationId: res.organization._id,
      organizationTitle: res.organization.title,
      timeLeftWithText:
        res.groupProjectSettings?.timeLeftWithText ??
        res.projectSettings?.timeLeftWithText,
    };
    if (window.revivr.isGroupRevivr) {
      mappedSettings = {
        ...commonSettings,
        customJoinUrlAndText: res.groupProjectSettings?.customJoinUrlAndText,
        learnerTeamText: res.groupProjectSettings?.learnerTeamText,
        joinCodeText: res.groupProjectSettings?.joinCodeText,
      } as SanityGroupSettings;
      // Sanitize, Remove navigation items without slug. They are not valid
      navigation.navigation = navigation.navigation.filter(
        (navigationItem) => navigationItem.slug.current !== undefined,
      );
      if (window.revivr.isLearner) {
        const learnerNavigationWithRoleSplitTemplateArray =
          await getLearnerNavigationWithRoleSplitTemplates(
            navigation.navigation,
          );

        mappedCourse = navigation.navigation.map((groupNavigation) => {
          if (groupNavigation._type === 'commonNavigation') {
            const mappedCommonNavigation = mapCommonNavigation(
              groupNavigation.slug.current,
              groupNavigation,
            );
            return mappedCommonNavigation as LearnerPageWithTemplate;
          }
          if (groupNavigation.learnerNavigation) {
            return mapLearnerNavigation(
              groupNavigation.slug.current,
              findTargetLearnerNavigation(
                groupNavigation.learnerNavigation,
                learnerNavigationWithRoleSplitTemplateArray,
              ),
            );
          }
        });
      } else {
        // Facilitator
        mappedCourse = navigation.navigation.map((navigationData) => {
          if (navigationData._type === 'commonNavigation') {
            const mappedCommonNavigation = mapCommonNavigation(
              navigationData.slug.current,
              navigationData,
            );
            return mappedCommonNavigation as FacilitatorPageWithTemplate;
          }
          const t = mapFacilitatorPageWithTemplate(
            navigationData.slug.current,
            navigationData.facilitatorNavigation,
          );
          return t;
        });
      }
    } else {
      // Solo Training
      mappedSettings = {
        ...commonSettings,
      } as SanitySettings;
    }

    const sanityContent: SanityContent = {
      course: mappedCourse,
      settings: mappedSettings,
      supportedLanguages: mappedSupportedLanguages,
      projectPages: {
        browserNotSupported: mapPageWithTemplate(
          res.projectPages?.browserNotSupported,
        ),
        pageForPcUsers: mapPageWithTemplate(res.projectPages?.pageForPcUsers),
        landingPage: mapPageWithTemplate(res.projectPages?.landingPage),
        learnerLandingPage: mapLearnerNavigation(
          '/',
          res.projectPages?.learnerLandingPage,
        ),
        facilitatorLandingPage: mapFacilitatorPageWithTemplate(
          '/',
          res.projectPages?.facilitatorLandingPage,
        ),
        waitingPage: mapLearnerNavigation('/', res.projectPages?.waitingPage),
        emailReminderPage: mapPageWithTemplate(
          res.projectPages?.emailReminderPage,
        ),
        privacyPolicy: mapPageWithTemplate(res.projectPages?.privacyPolicy),
        termsAndConditions: mapPageWithTemplate(
          res.projectPages?.termsAndConditions,
        ),
      },
    };
    return sanityContent;
  } catch (error: any) {
    logError(error, 'Error mapping', res);
    throw error;
  }
};

const mapSanityLocalizationToNavigation = (
  navigation: LocalizedGroupProjectNavigation,
) => {
  navigation.navigation = navigation.navigation.filter(
    (navigationItem) =>
      navigationItem?.translation[0]?.translations[0]?.value?.slug?.current !==
      undefined,
  );

  const mappedCourse = navigation.navigation.map((navigationData) => {
    return mapPageWithTemplate(
      navigationData.translation[0].translations[0].value,
    ) as PageWithTemplate;
  });
  return mappedCourse;
};

const mapSanityToNavigation = (navigation: LocalizedGroupProjectNavigation) => {
  navigation.navigation = navigation.navigation.filter(
    (navigationItem) => navigationItem.slug.current !== undefined,
  );

  const mappedCourse = navigation.navigation.map((navigationData) => {
    return mapPageWithTemplate(navigationData);
  });
  return mappedCourse;
};

const mapSanityNavigationContent = async (
  navigation,
): Promise<
  PageWithTemplate[] | LearnerPageWithTemplate[] | FacilitatorPageWithTemplate[]
> => {
  let mappedCourse:
    | PageWithTemplate[]
    | FacilitatorPageWithTemplate[]
    | LearnerPageWithTemplate[] = [];

  try {
    const language = getLocaleCodeForSanity();
    if (
      language !== constants.localeCode.EN &&
      navigation.navigation[0].translation &&
      navigation.navigation[0].translation[0].translations[0].value
    ) {
      return mapSanityLocalizationToNavigation(navigation);
    }
    return mapSanityToNavigation(navigation);
  } catch (error: any) {
    logError(error, 'Error mapping navigation', navigation);
    throw error;
  }
};

function getGroupProjectSettingsTranslation<
  T extends keyof SanityGroupSettings,
>(res, prop: T): SanityGroupSettings[T] {
  return res.groupProjectSettings?.translation?.[0]?.translations?.[0]?.value[
    prop
  ];
}

type SanityGroupSettingsKeys = {
  [P in keyof SanityGroupSettings]: P;
};
type SanitySettingsKeys = {
  [P in keyof SanitySettings]: P;
};

const sanitySettingsKeys: SanitySettingsKeys = {
  fadeInDuration: 'fadeInDuration',
  enableCameraFilter: 'enableCameraFilter',
  organizationId: 'organizationId',
  organizationTitle: 'organizationTitle',
  timeLeftWithText: 'timeLeftWithText',
};

const sanityGroupSettingsKeys: SanityGroupSettingsKeys = {
  ...sanitySettingsKeys,
  customJoinUrlAndText: 'customJoinUrlAndText',
  learnerTeamText: 'learnerTeamText',
  joinCodeText: 'joinCodeText',
};

function getProjectSettingsTranslation<T extends keyof SanitySettings>(
  res,
  prop: T,
): SanitySettings[T] {
  return res.projectSettings?.translation?.[0]?.translations?.[0]?.value[prop];
}

const mapSanityLocalizationToProject = async (
  res,
  navigation: LocalizedGroupProjectNavigation,
): Promise<SanityContent> => {
  let mappedCourse:
    | PageWithTemplate[]
    | FacilitatorPageWithTemplate[]
    | LearnerPageWithTemplate[];
  let mappedSettings: SanitySettings | SanityGroupSettings =
    {} as SanityGroupSettings;
  const mappedSupportedLanguages: string[] = [];
  const {
    customJoinUrlAndText,
    learnerTeamText,
    joinCodeText,
    timeLeftWithText,
  } = sanityGroupSettingsKeys;
  try {
    // Sanitize, Remove navigation items without slug. They are not valid
    if (window.revivr.isGroupRevivr) {
      navigation.navigation = navigation.navigation.filter(
        (navigationItem) => navigationItem?.slug?.current !== undefined,
      );
    }
    const commonSettings = {
      brandLogoAlt: res.organization.brandLogoAlt,
      brandLogoUrl: urlFor(res.organization.brandLogo),
      organizationId: res.organization._id,
      organizationTitle: res.organization.title,
      timeLeftWithText:
        getGroupProjectSettingsTranslation(res, timeLeftWithText) ??
        getProjectSettingsTranslation(res, timeLeftWithText),
    };

    if (window.revivr.isGroupRevivr) {
      mappedSettings = {
        ...commonSettings,
        customJoinUrlAndText: getGroupProjectSettingsTranslation(
          res,
          customJoinUrlAndText as 'customJoinUrlAndText',
        ),
        learnerTeamText: getGroupProjectSettingsTranslation(
          res,
          learnerTeamText,
        ),
        joinCodeText: getGroupProjectSettingsTranslation(res, joinCodeText),
      } as SanityGroupSettings;
      if (window.revivr.isLearner) {
        const learnerNavigationWithRoleSplitTemplateArray =
          await getLearnerNavigationWithRoleSplitTemplates(
            navigation.navigation,
          );

        mappedCourse = navigation.navigation.map((groupNavigation) => {
          return mapLearnerNavigation(
            groupNavigation.slug.current,
            findTargetLearnerNavigation(
              groupNavigation.learnerNavigation?.translation[0]?.translations[0]
                ?.value,
              learnerNavigationWithRoleSplitTemplateArray,
            ),
          ) as LearnerPageWithTemplate;
        });
      } else {
        mappedCourse = navigation.navigation.map((navigationData) => {
          const t = mapFacilitatorPageWithTemplate(
            navigationData.slug.current,
            navigationData.facilitatorNavigation?.translation[0]
              ?.translations[0]?.value,
          );
          return t as FacilitatorPageWithTemplate;
        });
      }
    } else {
      // Solo Training
      mappedSettings = {
        ...commonSettings,
      } as SanitySettings;
    }
    const localizeSanityContent: SanityContent = {
      course: mappedCourse,
      settings: mappedSettings,
      supportedLanguages: mappedSupportedLanguages,
      projectPages: {
        browserNotSupported: mapPageWithTemplate(
          res.projectPages?.browserNotSupported?.translation?.[0]
            ?.translations?.[0]?.value,
        ),
        pageForPcUsers: mapPageWithTemplate(
          res.projectPages?.pageForPcUsers?.translation?.[0]?.translations?.[0]
            ?.value,
        ),
        landingPage: mapPageWithTemplate(
          res.projectPages?.landingPage?.translation?.[0]?.translations?.[0]
            ?.value,
        ),
        learnerLandingPage: mapLearnerNavigation(
          '/',
          res.projectPages?.learnerLandingPage?.translation?.[0]
            ?.translations?.[0]?.value,
        ),
        facilitatorLandingPage: mapFacilitatorPageWithTemplate(
          '/',
          res.projectPages?.facilitatorLandingPage?.translation?.[0]
            ?.translations?.[0]?.value,
        ),
        waitingPage: mapLearnerNavigation(
          '/',
          res.projectPages?.waitingPage?.translation?.[0]?.translations?.[0]
            ?.value,
        ),
        emailReminderPage: mapPageWithTemplate(
          res.projectPages?.emailReminderPage?.translation?.[0]
            ?.translations?.[0]?.value,
        ),
        privacyPolicy: mapPageWithTemplate(
          res.projectPages?.privacyPolicy?.translation?.[0]?.translations?.[0]
            ?.value,
        ),
        termsAndConditions: mapPageWithTemplate(
          res.projectPages?.termsAndConditions?.translation?.[0]
            ?.translations?.[0]?.value,
        ),
      },
    };

    return localizeSanityContent;
  } catch (error: any) {
    logError(error, 'Error mapping', res);
    throw error;
  }
};

const mapSanityContent = async (res, navigation): Promise<SanityContent> => {
  const language = getLocaleCodeForSanity();
  if (window.revivr.isGroupRevivr) {
    if (window.revivr.isLearner) {
      if (
        language !== constants.localeCode.EN &&
        navigation.navigation[0]?.learnerNavigation?.translation &&
        navigation.navigation[0]?.learnerNavigation?.translation[0]
          ?.translations[0]?.value
      ) {
        return mapSanityLocalizationToProject(res, navigation);
      }
      return mapSanityToProject(res, navigation);
    }
    // Facilitator
    if (
      language !== constants.localeCode.EN &&
      navigation.navigation[0]?.facilitatorNavigation?.translation &&
      navigation.navigation[0]?.facilitatorNavigation?.translation[0]
        ?.translations[0]?.value
    ) {
      return mapSanityLocalizationToProject(res, navigation);
    }
    return mapSanityToProject(res, navigation);
  }
  // Solo
  if (
    language !== constants.localeCode.EN &&
    res.projectPages.landingPage.translation &&
    res.projectPages.landingPage.translation[0].translations[0].value
  ) {
    return mapSanityLocalizationToProject(res, navigation);
  }
  return mapSanityToProject(res, navigation);
};

const getProject = async (
  fetchProjectProp: FetchProject<any>,
): Promise<SanityContent> => {
  try {
    const res = await fetchProjectProp.fetchAsync();
    // Get navigation only for Group trainings
    let navigation = undefined;
    if (window.revivr.isGroupRevivr) {
      navigation = await getNavigation(fetchNavigation(res._id));
    }
    const [sanityContent, orgSettings] = await Promise.all([
      mapSanityContent(res, navigation),
      getSettings(fetchSettings(res.organization._id)),
    ]);
    sanityContent.settings.organizationSettings = orgSettings;
    sanityContent.supportedLanguages = await getSupportedLanguages(
      fetchLanguages(res._id),
    );
    window.sanity.sanityContent = sanityContent;
    return sanityContent;
  } catch (exception: any) {
    logError(exception);
    throw exception;
  }
};

const getNavigationCourse = async (
  fetchNavigationProp: API<SanityContent>,
): Promise<
  PageWithTemplate[] | LearnerPageWithTemplate[] | FacilitatorPageWithTemplate[]
> => {
  try {
    const res = await fetchNavigationProp.fetchAsync();
    const mappedCourse = await mapSanityNavigationContent(res);
    return mappedCourse;
  } catch (exception: any) {
    logError(exception);
    throw exception;
  }
};

export default {
  getProject,
  getNavigationCourse,
  mapSanityToProject,
};
