import { ReactNode } from 'react';
import { LottieProps } from '../../components/ReactLottie/index';
import { CustomButton } from '../../features/sanity/sanityTypes/customButton';
import { RaceTemplate } from '../../features/sanity/sanityTypes/templates';
import {
  AedPadHelpModal,
  AedPadTemplate,
} from '../../features/sanity/sanityTypes/templates/aedDraggablePadPageTemplate';
import { DynamicInputField } from '../../features/sanity/sanityTypes/templates/textAndImageTemplate';
import {
  AllowCameraPermissionMessage,
  QuizAnswerLabel,
  QuizButtonText,
  SanityLottieOptions,
  TrainingIntroMessages,
  TrainingPageBottomPart,
  TrainingPageCentralPart,
} from '../../features/sanity/types';
import { Image, ImageWithCustomSize } from '../Templates/InfoPageTypes';
import { Text } from '../Templates/Text';
import { DialogueText } from '../dialogPageTemplate/DialogueText';
import { ContinueCourseModal } from '../dialogPageTemplate/types/ContinueCourseModal';
import { DiplomaTemplate } from './diplomaTemplateTypes';
import { SanityButton, SanityImage, SanityText } from './sanityTypes';
import { QueryStringType } from '../../features/sanity/sanityTypes/QueryStringType';
import { BasicQueryStrings } from '../../context/UserProvider';

export type PageAudio = {
  src: string;
  audioType?: AudioTypes;
};

export enum AudioTypes {
  OnLoad = 'ONLOAD',
  OnClick = 'ONCLICK',
}

export interface Page {
  audio?: PageAudio[];
  imgSources?: string[];
  nextPageURL?: string;
  previousPageURL?: string;
  requireCameraPermission?: boolean;
  section?: SectionProps;
  skip?: boolean;
  slug?: string;
  timeToComplete: number;
  url: string;
}
export interface ProjectPages {
  browserNotSupported?: PageWithTemplate;
  emailReminderPage?: EmailReminderPageWithTemplate;
  facilitatorLandingPage?: FacilitatorPageWithTemplate;
  landingPage?: PageWithTemplate;
  learnerLandingPage?: LearnerPageWithTemplate;
  pageForPcUsers?: PageWithTemplate;
  privacyPolicy?: PageWithTemplate;
  termsAndConditions?: PageWithTemplate;
  waitingPage?: LearnerPageWithTemplate;
}

export type AudioProps = {
  enableAudio: boolean;
  audioDelay: number;
};
interface CustomURLAndText {
  customJoinUrl?: string;
  customJoinUrlText?: LocaleString;
}
export type Favicon = HTMLElement & { href: string };
type SanityCommonSettings = {
  brandLogoAlt?: string;
  brandLogoUrl?: string;
  enableCameraFilter: boolean;
  fadeInDuration: number;
  organizationId: string;
  organizationSettings?: OrganizationSettings;
  organizationTitle: string;
  timeLeftWithText: TimeLeftWithText;
};
export type SanitySettings = SanityCommonSettings;

export type SanityGroupSettings = SanityCommonSettings & {
  customJoinUrlAndText?: CustomURLAndText | undefined;
  joinCodeText: string;
  learnerTeamText: string;
};

interface TimeLeftWithText {
  timeLeftText: string;
  timeLeftMinuteText: string;
  timeLeftMinutesText: string;
}

export interface SanityFont {
  primaryFont?: string;
  secondaryFont?: string;
}

interface HexColor {
  hex: string;
}

interface SanityHexColor {
  primary?: HexColor;
  primary_500?: HexColor;
  primary_600?: HexColor;
  primary_700?: HexColor;
  primary_800?: HexColor;
  accent1_100?: HexColor;

  neutral_20?: HexColor;
  neutral_100?: HexColor;
  neutral_200?: HexColor;
  neutral_300?: HexColor;
  neutral_500?: HexColor;
  neutral_600?: HexColor;
  neutral_800?: HexColor;

  orange_100?: HexColor;
  orange_600?: HexColor;
  orange?: HexColor;
  green?: HexColor;
  green_100?: HexColor;
  green_600?: HexColor;
  blue_600?: HexColor;

  critical_20?: HexColor;
  critical_400?: HexColor;
}

export interface SanityColor {
  primary?: string;
  primary_500?: string;
  primary_600?: string;
  primary_700?: string;
  primary_800?: string;
  accent1_100?: string;

  neutral_20?: string;
  neutral_100?: string;
  neutral_200?: string;
  neutral_300?: string;
  neutral_500?: string;
  neutral_600?: string;
  neutral_800?: string;
  orange_100?: string;
  orange_600?: string;
  orange?: string;
  green?: string;
  green_100?: string;
  green_600?: string;
  blue_600?: string;

  critical_20?: string;
  critical_400?: string;

  // Life colors
  black: string;
  white: string;
  grey: string;
  accent1: string;
  accent2: string;
  correct: string;
  warning: string;
  critical: string;
  primary_20: string;
  primary_100: string;
  primary_200: string;
  primary_300: string;
  primary_400: string;
  accent1_20: string;
  accent1_200: string;
  accent1_300: string;
  accent1_400: string;
  accent1_500: string;
  accent1_600: string;
  accent1_700: string;
  accent1_800: string;
  accent2_20: string;
  accent2_100: string;
  accent2_200: string;
  accent2_300: string;
  accent2_400: string;
  accent2_500: string;
  accent2_600: string;
  accent2_700: string;
  accent2_800: string;
  correct_20: string;
  correct_100: string;
  correct_200: string;
  correct_300: string;
  correct_400: string;
  correct_500: string;
  correct_600: string;
  correct_700: string;
  correct_800: string;
  warning_20: string;
  warning_100: string;
  warning_200: string;
  warning_300: string;
  warning_400: string;
  warning_500: string;
  warning_600: string;
  warning_700: string;
  warning_800: string;
  critical_100: string;
  critical_200: string;
  critical_300: string;
  critical_500: string;
  critical_600: string;
  critical_700: string;
  critical_800: string;
  neutral_50: string;
  neutral_400: string;
  focus: string;
  focus_25: string;
  white_gradient: string;
  toast_info: string;
  toast_success: string;
  toast_warning: string;
  toast_error: string;
}

interface SanityTheme {
  fonts?: SanityFont;
  colors?: SanityHexColor;
  textAlignment?: string;
}

export interface OrganizationSettings {
  googleAnalytics?: GoogleAnalyticsSettings[];
  theme?: SanityTheme;
}

interface GoogleAnalyticsSettings {
  trackingId: string;
  name: string;
}
export interface SanityContent {
  supportedLanguages: string[];
  settings: SanitySettings | SanityGroupSettings;
  course:
    | PageWithTemplate[]
    | LearnerPageWithTemplate[]
    | FacilitatorPageWithTemplate[];
  projectPages: ProjectPages;
  navigation?: PageWithTemplate[];
}
export interface SelectionTemplate {
  buttons: SanityButton[];
  dropdownMenuItems: string[];
  dropdownMenuLabel: string;
  dropdownMenuPlaceholder: string;
  header: string;
  showTimeLeftBar: boolean;
  utmCampaignQueryString: boolean;
  queryStringType: keyof BasicQueryStrings;
}

export interface PageWithTemplate extends Page {
  aedBubbleTemplate?: AedBubbleTemplate;
  aedDraggablePadPageTemplate?: AEDDraggablePadPageTemplate;
  aedTemplate?: AedTemplateProps;
  clickableCardsTemplate?: clickableCardsTemplate;
  dialogPageTemplate?: DynamicDialoguePageTemplateProps;
  diplomaTemplate?: DiplomaTemplate;
  emailReminderTemplate?: EmailReminderTemplate;
  emergencyCallTemplate?: EmergencyCallTemplateProps;
  frontPageTemplate?: FrontPageTemplate;
  id?: string;
  inputTemplate?: InputPageTemplate;
  page?: Page;
  quizPageTemplate?: QuizPageTemplate;
  ratingPageTemplate?: RatingPageTemplate;
  selectionTemplate?: SelectionTemplate;
  template: string;
  textAndImageTemplate?: TextAndImageTemplate;
  trainingTemplate?: TrainingTemplate;
  videoPageTemplate?: VideoPageTemplate;
}
type GroupPageWithTemplate = {
  imageTemplate?: ImageTemplate;
};
type CommonPageWithTemplate = {
  raceTemplate?: RaceTemplate;
};
export type FacilitatorPageWithTemplate = CommonPageWithTemplate &
  GroupPageWithTemplate &
  Omit<PageWithTemplate, 'textAndImageTemplate'> & {
    connectedLearnersTemplate?: ConnectedLearnersTemplate;
    textAndImageTemplate?: FacilitatorTextAndImageTemplate;
    teamUpTheLearnersTemplate?: TeamUpTheLearnersTemplate;
  };

export function isFacilitatorPageWithTemplate(
  page:
    | FacilitatorPageWithTemplate
    | PageWithTemplate
    | LearnerPageWithTemplate,
): page is FacilitatorPageWithTemplate {
  return (
    (page as FacilitatorPageWithTemplate).connectedLearnersTemplate !==
      undefined ||
    (page as FacilitatorPageWithTemplate).teamUpTheLearnersTemplate !==
      undefined
  );
}

export type LearnerPageWithTemplate = CommonPageWithTemplate &
  GroupPageWithTemplate &
  Omit<PageWithTemplate, 'textAndImageTemplate'> & {
    learnerFrontPageTemplate?: LearnerFrontPageTemplate;
    learnerRoleSplitTemplate?: LearnerRoleSplitTemplate;
    learnerShowMyTeamTemplate?: LearnerShowMyTeamTemplate;
    textAndImageTemplate?: LearnerTextAndImageTemplate;
  };

interface EmailReminderPageWithTemplate extends PageWithTemplate {
  emailReminderTemplate?: EmailReminderTemplate;
}

export function isLearnerPageWithTemplate(
  page:
    | LearnerPageWithTemplate
    | FacilitatorPageWithTemplate
    | PageWithTemplate,
): page is LearnerPageWithTemplate {
  return (
    (page as LearnerPageWithTemplate).learnerFrontPageTemplate !== undefined
  );
}

/**
 * Interfaces related to TextAndImagePage
 */

export type HorizontalAlignment = 'center' | 'right' | 'left';
export interface SanityBanner {
  frontImage: SanityImage;
  frontImageAlignment: HorizontalAlignment;
  backImage?: SanityImage;
}

export interface LearnerInputField {
  label: string;
  placeholder: string;
  validationErrorMessage: string;
  backendCodeIsNotValidErrorMessage: string;
  backendGeneralError: string;
}

export interface SanityCountDown {
  duration: number;
  delay: number;
  playText?: string;
  pauseText?: string;
  hasBackButton?: boolean;
  hasSkipButton?: boolean;
  backText?: string;
}

interface TrainingPage {
  allowCameraPermissionMessage?: AllowCameraPermissionMessage;
  bottomPart?: TrainingPageBottomPart;
  centralPart?: TrainingPageCentralPart;
  centralPartPlaceholder?: any;
  duration?: number;
  enableAudio?: boolean;
  lottie?: SanityLottieOptions;
  topPart?: 'ambulance';
  trainingIntro?: TrainingIntroMessages;
}
export interface ResultData {
  heading?: string;
  subHeading?: string;
  iconImage?: Image;
  buttons: SanityButton[];
}
export interface TrainingResultPages {
  compressFaster: ResultData;
  compressSlower: ResultData;
  goodRate: ResultData;
  badRate: ResultData;
  couldNotDetect: ResultData;
  debriefWithNoCameraAccess: ResultData;
  footerText: string;
}
export interface TrainingTemplate {
  trainingPage?: TrainingPage;
  resultPages?: TrainingResultPages;
}
export type ImageTemplate = {
  buttons: SanityButton[];
  countDown?: SanityCountDown;
  enableAudio: boolean;
  enableAutoplay: boolean;
  image: SanityImage;
  verticalButtons: boolean;
};

export interface TextAndImageTemplate {
  buttons?: SanityButton[];
  countDown?: SanityCountDown;
  dialog?: TextAndImageTemplateDialog;
  enableInputField?: boolean;
  enableAudio?: boolean;
  enableAutoplay?: boolean;
  enableDialogText?: boolean;
  image?: SanityImage;
  inputField?: DynamicInputField;
  showTimeLeftBar?: boolean;
  text?: SanityText;
  verticalButtons?: boolean;
}

export interface TextAndImageTemplateDialog {
  showInitialDialog?: boolean;
  initialDialog?: TextAndImageTemplateDialogText;
  showAnswerDialog?: boolean;
  answerDialog?: TextAndImageTemplateDialogText;
}

export interface TextAndImageTemplateDialogText extends Text {
  text?: ReactNode;
  textAlignCenter?: boolean;
  pointerAlignLeft?: boolean;
  button?: CustomButton;
}

export interface FacilitatorTextAndImageTemplate extends TextAndImageTemplate {
  completedText: string;
  displayCompletedLearners: boolean;
}
export function isFacilitatorTextAndImageTemplate(
  page:
    | TextAndImageTemplate
    | FacilitatorTextAndImageTemplate
    | LearnerTextAndImageTemplate,
): page is FacilitatorTextAndImageTemplate {
  return (
    (page as FacilitatorTextAndImageTemplate).displayCompletedLearners !==
    undefined
  );
}
export type LearnerTextAndImageTemplate = TextAndImageTemplate & {
  displayTeamName: boolean;
};
export function isLearnerTextAndImageTemplate(
  page:
    | TextAndImageTemplate
    | FacilitatorTextAndImageTemplate
    | LearnerTextAndImageTemplate,
): page is LearnerTextAndImageTemplate {
  return !(
    (page as FacilitatorTextAndImageTemplate).displayCompletedLearners !==
      undefined ||
    (page as LearnerTextAndImageTemplate).displayTeamName === undefined
  );
}

export interface ConnectedLearnersTemplate {
  banner?: SanityBanner;
  buttons?: SanityButton[];
  classroomCode?: {
    displayClassroomCode?: boolean;
    text?: string;
  };
  connectedLearners?: {
    displayConnectedLearners?: boolean;
    text?: string;
  };
  displayJoinLink?: boolean;
  image?: SanityImage;
  text?: SanityText;
}
export interface InputPageTemplate {
  text?: SanityText;
  buttons?: SanityButton[];
  verticalButtons?: boolean;
  inputField?: InputField;
  firebaseStorageCollection: string;
}

export interface LearnerShowMyTeamTemplate {
  image?: SanityImage;
  text?: SanityText;
}

interface InputField {
  label?: string;
  minimumCharsToValidate?: number;
  placeholder?: string;
  validationText?: string;
}

type LanguageModel = {
  headingText?: string;
  buttonText?: string;
};

export interface FrontPageTemplate {
  text?: SanityText;
  image?: SanityImage;
  banner?: SanityBanner;
  buttons?: SanityButton[];
  verticalButtons?: boolean;
  showVersionNumber?: boolean;
  enableLanguageSelectionField?: boolean;
  languageModal?: LanguageModel;
}

export type SanityCard = {
  heading: string;
  subHeading: string;
  cardClicked: (event) => void;
  iconImage: Image;
  navigation: string;
  url?: string;
  openInNewTab: boolean;
  queryString?: QueryStringType;
};

export type clickableCardsTemplate = {
  text?: SanityText;
  banner?: SanityBanner;
  cards: SanityCard[];
  showVersionNumber: boolean;
};

export interface LearnerFrontPageTemplate {
  text?: SanityText;
  image?: SanityImage;
  banner?: SanityBanner;
  buttons?: SanityButton[];
  verticalButtons?: boolean;
  showVersionNumber?: boolean;
  inputField: LearnerInputField;
}

interface LearnerRoleSplitTemplateTeam {
  template: string;
  textAndImageTemplate?: LearnerTextAndImageTemplate;
  aedDraggablePadPageTemplate?: AEDDraggablePadPageTemplate;
  aedTemplate?: AedTemplateProps;
  emergencyCallTemplate?: EmergencyCallTemplateProps;
  quizPageTemplate?: QuizPageTemplate;
  ratingPageTemplate?: RatingPageTemplate;
  trainingTemplate?: TrainingTemplate;
  videoPageTemplate?: VideoPageTemplate;
}
export interface LearnerRoleSplitTemplate {
  roleA: LearnerRoleSplitTemplateTeam;
  roleB: LearnerRoleSplitTemplateTeam;
}

export interface VideoPageTemplate {
  videoUrl: string;
  videoIntroText: string;
  addContinueButton?: boolean;
  addBackButton?: boolean;
  continueButtonText?: string;
  backButtonText?: string;
  navigateOnVideoComplete?: boolean;
  continueButtonRevealTime?: number;
}
export type QuizPageTemplate = {
  answerExplanation: string;
  answerOptions: AnswerOption[];
  displayTeamName: boolean;
  image: SanityImage;
  isSurvey: boolean;
  question: string;
  quizAnswerLabel: QuizAnswerLabel;
  quizButtonText: QuizButtonText;
  showTimeLeftBar: boolean;
};

export type RatingIconsAltText = {
  oneRatingAlt: string;
  twoRatingAlt: string;
  threeRatingAlt: string;
  fourRatingAlt: string;
  fiveRatingAlt: string;
};

export type RatingPageTemplate = {
  custombutton: SanityButton;
  displayTeamName?: boolean;
  fiveStarRatingText: string;
  fourStarRatingText: string;
  headingText: string;
  image?: SanityImage;
  oneStarRatingText: string;
  ratingIcons?: RatingIcons;
  ratingIconsAltText?: RatingIconsAltText;
  showTimeLeftBar: boolean;
  subheadingText: string;
  threeStarRatingText: string;
  twoStarRatingText: string;
};

interface EmailContent {
  subject: string;
  heading?: string;
  body: string;
  buttonText: string;
}

export interface EmailReminderTemplate {
  image?: SanityImage;
  mainText: string;
  inputField: InputField;
  emailContent: EmailContent;
  emailConfirmationText: string;
  sendEmailButtonText: string;
}

export interface RatingIcons {
  selectedIcon?: SanityImage;
  unSelectedIcon?: SanityImage;
}

interface AEDPadPlacementValues {
  leftPadAreaImage: Image;
  leftPadAreaHeight: number;
  leftPadAreaWidth: number;
  leftPadAreaXValue: number;
  leftPadAreaYValue: number;
  leftPadSize: number;
  rightPadAreaImage: Image;
  rightPadAreaHeight: number;
  rightPadAreaWidth: number;
  rightPadAreaXValue: number;
  rightPadAreaYValue: number;
  rightPadSize: number;
}

interface RightPadAlternateImageGroup {
  rightX: number;
  bottomY: number;
  leftX: number;
  topY: number;
  alternateRightPadImage?: string;
  enableRightPadAlternateImage?: boolean;
}

export interface AEDDraggablePadPageTemplate {
  aedLeftPad?: SanityImage;
  aedPadCorrectPosition?: AedPadTemplate;
  aedPadHelp?: AedPadTemplate;
  aedPadHelpModal?: AedPadHelpModal;
  aedPadInCorrectPosition?: AedPadTemplate;
  aedPadPlacement?: AedPadTemplate;
  aedPadPlacementValues?: AEDPadPlacementValues;
  aedRightPad?: SanityImage;
  correctImage?: SanityImage;
  displayTeamName?: boolean;
  enableRightPadAlternateImage?: boolean;
  image?: SanityImage;
  rightPadAlternateImageGroup?: RightPadAlternateImageGroup;
  showTimeLeftBar?: boolean;
  useOnlyOnePad: boolean;
  enforceStrictPadPlacement: boolean;
}

export type EmergencyCallTemplateProps = {
  bottomText?: string;
  call911ModalHeaderText: string;
  call911ModalBodyText: string;
  call911ModalImage?: SanityImage;
  call911ModalPrimaryButton: string;
  call911ModalSecondaryButton: string;
  displayTeamName: boolean;
  headerText: string;
  infoHeaderText: string;
  numberToCall: string;
  practiceCallingText: string;
  showBottomButton?: boolean;
  showBottomText?: boolean;
  showCall911Modal: boolean;
  showTimeLeftBar: boolean;
  whyTrainButton: string;
  whyTrainModalBodyText: ReactNode;
  whyTrainModalButtonText: string;
  whyTrainModalHeaderText: string;
};

export interface TeamUpTheLearnersTemplate {
  text?: SanityText;
  image?: SanityImage | undefined;
  buttons?: SanityButton[];
  verticalButtons?: boolean;
  showTimeLeftBar?: boolean;
  enableAudio?: boolean;
}

export interface DynamicDialoguePageTemplateProps {
  text?: Text;
  buttons?: SanityButton[];
  continueCourseModal: ContinueCourseModal;
  countDown?: SanityCountDown;
  replyBox: ReplyBoxProps;
  speechbubbleContent?: ReactNode;
  mainImg?: ImageWithCustomSize;

  enableAutoplay?: boolean;
}

export type AedTemplateProps = {
  displayTeamName?: boolean;
  showTimeLeftBar?: boolean;
  image?: SanityImage;
  shockButton?: SanityImage;
  turnOnButton?: SanityImage;
  continueCourseModal: ContinueCourseModal;
  dialog?: DialogueText;
  text?: Text;
  aedState: 'shock' | 'on';
};

export interface AedBubbleTemplate {
  image?: SanityImage;
  dialogText?: DialogueText;
  text?: Text;
  navigateAutomatically?: boolean;
  continueCourseModal?: ContinueCourseModal;
  lottie?: LottieProps;
}

export interface SectionProps {
  continueCourseModal: ContinueCourseModal;
  title: string;
}

// TODO we have the same interface in DynamicDialoguePageProps.ts and ReplyBoxProps.tsx - refactor
interface ReplyBoxProps {
  button: string;
  text: string;
}

export interface AnswerOption {
  isCorrect: boolean;
  answerString: string;
}

export interface LocaleString {
  en: string;
  nb_NO: string;
  fr_FR: string;
  cy_GB: string;
  es_ES: string;
}
