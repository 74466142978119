import React, { useEffect, useState } from 'react';
import { MessageWithDuration } from '../../features/sanity/types';
import GridOverlay from './GridOverlay';

interface WarmupOverlayProps {
  introMessages?: MessageWithDuration[];
  isVisible?: boolean;
  wait?: number;
}

const WarmupOverlay: React.FunctionComponent<WarmupOverlayProps> = ({
  introMessages,
  isVisible = true,
  wait = 0,
}: WarmupOverlayProps) => {
  let updatedWait = wait;

  return (
    <>
      {introMessages?.map((messageWithDuration) => {
        const Message = (
          <GridOverlay
            showCounter={false}
            isVisible={isVisible}
            wait={updatedWait}
            duration={messageWithDuration.duration}
          >
            {messageWithDuration.message}
          </GridOverlay>
        );
        updatedWait += messageWithDuration.duration ?? 0;
        return Message;
      }) ?? null}
    </>
  );
};
type CountdownSequenceProps = {
  trainingStartsIn?: MessageWithDuration;
  wait?: number;
};

const CountdownSequence: React.FunctionComponent<CountdownSequenceProps> = ({
  trainingStartsIn,
  wait,
}: CountdownSequenceProps) => {
  return (
    <GridOverlay
      showCounter
      wait={wait}
      duration={trainingStartsIn?.duration ?? 5}
    >
      {trainingStartsIn?.message ?? 'Start practising in:'}
    </GridOverlay>
  );
};

type TrainingStartSequenceProps = {
  isLoaded: boolean;
  enableCountdown: boolean;
  OverlayTimeout: () => void;
  trainingStartsIn?: MessageWithDuration;
  introMessages?: MessageWithDuration[];
};
export const TrainingStartSequence: React.FunctionComponent<
  TrainingStartSequenceProps
> = ({
  enableCountdown,
  introMessages,
  isLoaded,
  OverlayTimeout,
  trainingStartsIn,
}: TrainingStartSequenceProps) => {
  const [countdown, setCountdown] = useState(0);

  const introMessagesDuration =
    introMessages?.reduce(
      (totalDuration, message) => totalDuration + message.duration,
      0,
    ) ?? 0;

  useEffect(() => {
    const countDownDuration = () => {
      if (!enableCountdown || !trainingStartsIn) {
        return 0;
      }
      return trainingStartsIn.duration ?? 0;
    };
    const totalDuration = introMessagesDuration + countDownDuration();
    setCountdown(totalDuration);

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          OverlayTimeout();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [introMessages, trainingStartsIn]);

  return (
    <>
      <WarmupOverlay isVisible={!isLoaded} />
      {isLoaded && (
        <>
          {introMessages && <WarmupOverlay introMessages={introMessages} />}
          {enableCountdown && trainingStartsIn && (
            <CountdownSequence
              trainingStartsIn={trainingStartsIn}
              wait={introMessagesDuration}
            />
          )}
        </>
      )}
    </>
  );
};
