import constants from '../constants/Constants';

const SESSION_ID_KEY = 'sessionId';
const {
  AUTHENTICATION_TOKEN_KEY,
  CLASSROOM_CODE_KEY,
  DISTRIBUTION_KEY,
  IS_SCORM_KEY,
  LAST_LEARNER_ROLE_KEY,
  LAST_LEARNER_TEAM_KEY,
  LEARNER_ID_KEY,
  LEARNER_ROLE_KEY,
  LEARNER_TEAM_KEY,
  LANGUAGE_CODE_KEY,
  ORGANIZATION_KEY,
  PORTAL_ID_LOCAL_STORAGE_KEY,
  SANITY_KEY,
  SANITY_PROJECT_KEY,
  SHARE_LEVEL_KEY,
  START_OF_JOURNEY_KEY,
  TAG_KEY,
  TRAINING_ROLE,
  TRAINING_TYPE,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
  RACE_TEAMS,
  RACE_DEBUG_LOGS,
} = constants.queryKeys;

const getOrganization = (): string | null => {
  return localStorage.getItem(ORGANIZATION_KEY);
};

const setOrganization = (data: string): void => {
  localStorage.setItem(ORGANIZATION_KEY, data);
};

const getPortalId = (): string | null => {
  return localStorage.getItem(PORTAL_ID_LOCAL_STORAGE_KEY);
};
const setPortalId = (data: string): void => {
  localStorage.setItem(PORTAL_ID_LOCAL_STORAGE_KEY, data);
};
const clearPortalId = (): void => {
  localStorage.removeItem(PORTAL_ID_LOCAL_STORAGE_KEY);
};

const getShareLevel = (): string | null => {
  return localStorage.getItem(SHARE_LEVEL_KEY);
};

const setShareLevel = (data: string): void => {
  localStorage.setItem(SHARE_LEVEL_KEY, data);
};

const getTag = (): string | null => {
  return localStorage.getItem(TAG_KEY);
};

const setTag = (data: string): void => {
  localStorage.setItem(TAG_KEY, data);
};

const getDistribution = (): string | null => {
  return localStorage.getItem(DISTRIBUTION_KEY);
};

const setDistribution = (data: string): void => {
  localStorage.setItem(DISTRIBUTION_KEY, data);
};

const getStartOfJourney = (): string | null => {
  return localStorage.getItem(START_OF_JOURNEY_KEY);
};

const setStartOfJourney = (data: string): void => {
  localStorage.setItem(START_OF_JOURNEY_KEY, data);
};

const clearSessionId = (): void => {
  localStorage.removeItem(SESSION_ID_KEY);
};

const getSessionId = (): string | null => {
  return localStorage.getItem(SESSION_ID_KEY);
};

const setSessionId = (data: string): void => {
  localStorage.setItem(SESSION_ID_KEY, data);
};

const clearOrganization = (): void => {
  localStorage.removeItem(ORGANIZATION_KEY);
};

const clearTag = (): void => {
  localStorage.removeItem(TAG_KEY);
};

const clearDistribution = (): void => {
  localStorage.removeItem(DISTRIBUTION_KEY);
};

const clearStartOfJourney = (): void => {
  localStorage.removeItem(START_OF_JOURNEY_KEY);
};

const clearShareLevel = (): void => {
  localStorage.removeItem(SHARE_LEVEL_KEY);
};

const clearUtmCampaign = (): void => {
  localStorage.removeItem(UTM_CAMPAIGN);
};
const setUtmCampaign = (data: string): void => {
  localStorage.setItem(UTM_CAMPAIGN, data);
};

const clearUtmMedium = (): void => {
  localStorage.removeItem(UTM_MEDIUM);
};

const clearUtmSource = (): void => {
  localStorage.removeItem(UTM_SOURCE);
};

const setProjectId = (projectId: string): void => {
  localStorage.setItem(SANITY_PROJECT_KEY, projectId);
};

const getProjectId = (): string | null => {
  return localStorage.getItem(SANITY_PROJECT_KEY);
};

const getSanity = (): string | null => {
  return localStorage.getItem(SANITY_KEY);
};

const setClassroomCodeToLocalStorage = (code: string): void => {
  localStorage.setItem(CLASSROOM_CODE_KEY, code);
};

const removeClassroomCodeFromLocalStorage = (): void => {
  localStorage.removeItem(CLASSROOM_CODE_KEY);
};

const getClassroomCodeFromLocalStorage = (): string | null => {
  return localStorage.getItem(CLASSROOM_CODE_KEY);
};

const setTrainingRoleToLocalStorage = (role: string): void => {
  localStorage.setItem(TRAINING_ROLE, role);
};

const removeTrainingRoleFromLocalStorage = (): void => {
  localStorage.removeItem(TRAINING_ROLE);
};

const getTrainingRoleFromLocalStorage = (): string | null => {
  return localStorage.getItem(TRAINING_ROLE);
};

const setTrainingTypeToLocalStorage = (type: string): void => {
  localStorage.setItem(TRAINING_TYPE, type);
};

const getTrainingTypeFromLocalStorage = (): string | null => {
  return localStorage.getItem(TRAINING_TYPE);
};

const removeTrainingTypeFromLocalStorage = (): void => {
  localStorage.removeItem(TRAINING_TYPE);
};

const setAuthenticationToken = (token: string): void => {
  localStorage.setItem(AUTHENTICATION_TOKEN_KEY, token);
};

const getAuthenticationToken = (): string | null => {
  return localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
};

const clearAuthenticationToken = (): void => {
  localStorage.removeItem(AUTHENTICATION_TOKEN_KEY);
};

const getLearnerIdFromLocalStorage = (): string | null => {
  return localStorage.getItem(LEARNER_ID_KEY);
};

const removeLearnerIdFromLocalStorage = (): void => {
  localStorage.removeItem(LEARNER_ID_KEY);
};

const setLearnerIdToLocalStorage = (id: string): void => {
  localStorage.setItem(LEARNER_ID_KEY, id);
};

const getLearnerTeamFromLocalStorage = (): string | null => {
  return localStorage.getItem(LEARNER_TEAM_KEY);
};

const setLearnerTeamToLocalStorage = (team: string): void => {
  localStorage.setItem(LEARNER_TEAM_KEY, team);
};

const removeLearnerTeamFromLocalStorage = (): void => {
  localStorage.removeItem(LEARNER_TEAM_KEY);
};

const getLearnerRoleFromLocalStorage = (): string | null => {
  return localStorage.getItem(LEARNER_ROLE_KEY);
};

const removeLearnerRoleFromLocalStorage = (): void => {
  localStorage.removeItem(LEARNER_ROLE_KEY);
};

const setLearnerRoleToLocalStorage = (role: string): void => {
  localStorage.setItem(LEARNER_ROLE_KEY, role);
};

const getLastLearnerTeamFromLocalStorage = (): string | null => {
  return localStorage.getItem(LAST_LEARNER_TEAM_KEY);
};

const setLastLearnerTeamToLocalStorage = (team: string): void => {
  localStorage.setItem(LAST_LEARNER_TEAM_KEY, team);
};

const getLastLearnerRoleFromLocalStorage = (): string | null => {
  return localStorage.getItem(LAST_LEARNER_ROLE_KEY);
};

const setLastLearnerRoleToLocalStorage = (role: string): void => {
  localStorage.setItem(LAST_LEARNER_ROLE_KEY, role);
};

const setIsScormFromLocalStorage = (value: string): void => {
  localStorage.setItem(IS_SCORM_KEY, value);
};

const getIsScormFromLocalStorage = (): boolean | null => {
  return !!localStorage.getItem(IS_SCORM_KEY);
};

const setLanguageCodeToLocalStorage = (code: string): void => {
  localStorage.setItem(LANGUAGE_CODE_KEY, code);
};

const getLanguageCodeFromLocalStorage = (): string | null => {
  return localStorage.getItem(LANGUAGE_CODE_KEY);
};

const removeLastLearnerTeamFromLocalStorage = (): void => {
  localStorage.removeItem(LAST_LEARNER_TEAM_KEY);
};

const removeLastLearnerRoleFromLocalStorage = (): void => {
  localStorage.removeItem(LAST_LEARNER_ROLE_KEY);
};

const cleanupRace = () => {
  localStorage.removeItem(RACE_TEAMS);
  localStorage.removeItem(RACE_DEBUG_LOGS);
};

const removeProjectIdFromLocalStorage = (): void => {
  localStorage.removeItem(SANITY_PROJECT_KEY);
};

const getUTMSource = (): string | null => {
  return localStorage.getItem(UTM_SOURCE);
};

const setUTMSource = (data: string): void => {
  localStorage.setItem(UTM_SOURCE, data);
};

const getUTMMedium = (): string | null => {
  return localStorage.getItem(UTM_MEDIUM);
};

const setUTMMedium = (data: string): void => {
  localStorage.setItem(UTM_MEDIUM, data);
};

const getUTMCampaign = (): string | null => {
  return localStorage.getItem(UTM_CAMPAIGN);
};

export const LocalStorageService = {
  clearAuthenticationToken,
  clearDistribution,
  clearOrganization,
  clearPortalId,
  clearSessionId,
  clearShareLevel,
  clearStartOfJourney,
  clearTag,
  clearUtmCampaign,
  clearUtmMedium,
  clearUtmSource,
  getAuthenticationToken,
  getClassroomCodeFromLocalStorage,
  getDistribution,
  getIsScormFromLocalStorage,
  getLanguageCodeFromLocalStorage,
  getLastLearnerRoleFromLocalStorage,
  getLastLearnerTeamFromLocalStorage,
  getLearnerIdFromLocalStorage,
  getLearnerRoleFromLocalStorage,
  getLearnerTeamFromLocalStorage,
  getOrganization,
  getPortalId,
  getProjectId,
  getSanity,
  getSessionId,
  getShareLevel,
  getStartOfJourney,
  getTag,
  getTrainingRoleFromLocalStorage,
  getTrainingTypeFromLocalStorage,
  setAuthenticationToken,
  setClassroomCodeToLocalStorage,
  setDistribution,
  setIsScormFromLocalStorage,
  setLanguageCodeToLocalStorage,
  setLastLearnerRoleToLocalStorage,
  setLastLearnerTeamToLocalStorage,
  setLearnerIdToLocalStorage,
  setLearnerRoleToLocalStorage,
  setLearnerTeamToLocalStorage,
  setOrganization,
  setPortalId,
  setProjectId,
  setSessionId,
  setShareLevel,
  setStartOfJourney,
  setTag,
  setTrainingRoleToLocalStorage,
  setTrainingTypeToLocalStorage,
  removeClassroomCodeFromLocalStorage,
  removeTrainingRoleFromLocalStorage,
  removeTrainingTypeFromLocalStorage,
  removeLearnerTeamFromLocalStorage,
  removeLearnerRoleFromLocalStorage,
  removeLastLearnerTeamFromLocalStorage,
  removeLastLearnerRoleFromLocalStorage,
  removeLearnerIdFromLocalStorage,
  removeProjectIdFromLocalStorage,
  cleanupRace,
  getUTMSource,
  getUTMMedium,
  getUTMCampaign,
  setUTMSource,
  setUTMMedium,
  setUtmCampaign,
};
