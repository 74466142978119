/* eslint-disable import/no-unused-modules */
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import constants from '../constants/Constants';
import { NavigationContextProps } from '../context/navigation/navigationContextProps';
import { SanityCourseContextProps } from '../context/sanity/sanityCourseContextProps';
import {
  isGroupFacilitatorTrainingStartPage,
  isGroupLearnerTrainingStartPage,
  isSoloTrainingStartPage,
} from '../features/groupRevivr/helper/GroupTrainingUrl';
import { getQuerystringParameter } from '../helper';
import { GuidService, LocalStorageService } from '../services';
import { logError } from '../services/errorHandling';
import autoNavigationHelper from '../helper/autoNavigationHelper';
import { LDClient } from 'launchdarkly-react-client-sdk';

export type CustomErrorEvent = {
  message?: string;
  event?: unknown;
  timestamp: Date;
  stack?: string;
};

declare global {
  interface Window {
    config: {
      debugMode: boolean;
      automaticTestMode?: {
        isTestMode: boolean;
        autoPlayDuration: number;
        autoPlayDelay?: number;
        cameraTrainingDuration?: number;
      };
    };
    revivr: {
      isGroupRevivr: boolean;
      isLearner: boolean;
      isTeamedUpSession: boolean;
      code?: number | null;
      learnerId?: string | null;
      errorMessages?: CustomErrorEvent[];
      isPreviewMode?: boolean;
      isScorm?: boolean;
    };
    mock: {
      sanityProvider?: SanityCourseContextProps;
      navigationProvider?: NavigationContextProps;
    };
    ldClient: LDClient | undefined;
    Appcues: any;
  }
}

const setupDebugFunctionality = () => {
  const query = new URLSearchParams(window.location.search);
  window.config = {
    debugMode: query.get('isDebug') !== null || query.get('debug') !== null,
  };
};

export const generateSessionId = (): void => {
  autoNavigationHelper.setupTestMode();
  const urlSessionId = getQuerystringParameter('sessionId');

  // Allow to override the sessionId from the querystring.
  // Usually used to track sessions where the user has been redirected using qr code
  if (urlSessionId) {
    LocalStorageService.setSessionId(urlSessionId);
    return;
  }

  if (LocalStorageService.getSessionId() !== null) {
    return;
  }
  const newSessionId = GuidService();
  if (newSessionId === null) {
    logError(new Error('SessionId generation failed'));
    return;
  }
  LocalStorageService.setSessionId(newSessionId);
};

const UseGlobalConfig = () => {
  setupDebugFunctionality();
  // When user refresh then we need to reset local storage parameters
  if (
    isSoloTrainingStartPage() ||
    isGroupFacilitatorTrainingStartPage() ||
    isGroupLearnerTrainingStartPage()
  ) {
    LocalStorageService.removeClassroomCodeFromLocalStorage();
    LocalStorageService.removeTrainingRoleFromLocalStorage();
    LocalStorageService.removeTrainingTypeFromLocalStorage();
    LocalStorageService.removeLearnerTeamFromLocalStorage();
    LocalStorageService.removeLearnerRoleFromLocalStorage();
    LocalStorageService.removeLastLearnerTeamFromLocalStorage();
    LocalStorageService.removeLastLearnerRoleFromLocalStorage();
    LocalStorageService.removeLearnerIdFromLocalStorage();
    LocalStorageService.cleanupRace();
    LocalStorageService.removeProjectIdFromLocalStorage();
  }

  const startHotjar = () => {
    hotjar.initialize(
      parseInt(constants.hotjar.hjid, 10),
      parseInt(constants.hotjar.hjsv, 10),
    );
  };

  const activateThirdPartyScripts = () => {
    startHotjar();
  };

  useEffect(() => {
    if (!constants.setThirdPartyScriptsInGTM) {
      activateThirdPartyScripts();
    }
  }, []);

  useEffect(() => {
    if (window.Appcues === undefined) {
      return;
    }
    window.Appcues.AppcuesSettings = {
      enableURLDetection: true,
    };
    window.Appcues.anonymous();
  }, [window.Appcues]);

  useEffect(() => {
    generateSessionId();
  }, []);

  const generateLearnerId = () => {
    const existingLearnerId =
      LocalStorageService.getLearnerIdFromLocalStorage();
    if (existingLearnerId) {
      return existingLearnerId;
    }

    if (window.revivr?.isGroupRevivr && window.revivr?.isLearner) {
      const newLearnerId = GuidService();
      LocalStorageService.setLearnerIdToLocalStorage(newLearnerId);
      return newLearnerId;
    }
    return null;
  };

  const isPreviewMode =
    getQuerystringParameter('isPreview') !== null &&
    getQuerystringParameter('isPreview') === 'true';

  // Usually set in preview mode
  const isPreviewModeGroup =
    isPreviewMode &&
    getQuerystringParameter('isGroup') !== null &&
    getQuerystringParameter('isGroup') === 'true';

  // Usually set in preview mode
  const isPreviewModeLearner =
    isPreviewMode &&
    getQuerystringParameter('isLearner') !== null &&
    getQuerystringParameter('isLearner') === 'true';

  window.revivr = {
    code:
      LocalStorageService.getClassroomCodeFromLocalStorage() === null ||
      LocalStorageService.getClassroomCodeFromLocalStorage() === ''
        ? null
        : Number(LocalStorageService.getClassroomCodeFromLocalStorage()),
    isLearner:
      // isLearner is true for learners in both Solo Revivr and Group Revivr.
      LocalStorageService.getTrainingRoleFromLocalStorage() ===
        constants.trainingMode.LEARNER ||
      (!isGroupFacilitatorTrainingStartPage() &&
        LocalStorageService.getTrainingRoleFromLocalStorage() !==
          constants.trainingMode.FACILITATOR),

    isGroupRevivr:
      isGroupFacilitatorTrainingStartPage() ||
      isGroupLearnerTrainingStartPage() ||
      LocalStorageService.getTrainingTypeFromLocalStorage() ===
        constants.trainingMode.GROUP ||
      LocalStorageService.getTrainingTypeFromLocalStorage() ===
        constants.trainingMode.TEAMUP,
    learnerId: generateLearnerId(),
    isTeamedUpSession: false,
  };

  if (isPreviewMode) {
    window.revivr.isPreviewMode = isPreviewMode;
    window.revivr.isLearner = isPreviewModeLearner;
    window.revivr.isGroupRevivr = isPreviewModeGroup;
  }

  // revivr.learnerId sometimes tries to update too quickly and needs to be checked again
  if (window.revivr?.learnerId === null) {
    window.revivr.learnerId = generateLearnerId();
  }
};

export default UseGlobalConfig;
