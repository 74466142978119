import { css, styled } from 'styled-components';
import mediaMedium from '../Templates/MediaMedium';

export const MainContentContainer = styled.div`
  display: grid;
  grid-row: 2 / -2;
  grid-column: 2 / -2;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  ${mediaMedium(css`
    grid-row: 2 / 3;
    grid-column: 2 / -2;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1.5fr;
    justify-content: center;
    align-content: center;
  `)};
`;

export const ImageContainer = styled.div`
  display: flex;

  ${mediaMedium(css`
    grid-column: 1/2;
    grid-row: 1/-1;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
  `)}
`;

export const BottomContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: -2;
  display: grid;

  ${mediaMedium(css`
    grid-column: -3 / -2;
    height: max-content;
    grid-row: 3 / 4;
    height: 48px;
    justify-self: flex-end;
    width: 200px;
  `)}
`;
