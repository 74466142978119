import { QRCodeSVG } from 'qrcode.react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../context/ViewportProvider';
import SettingsHelper from '../../helper/SettingsHelper';
import {
  addProjectQueryStringToUrl,
  addSessionIdQueryStringToUrl,
} from '../../helper/UrlHelper';
import mediaMedium from '../../pageTemplates/Templates/MediaMedium';
import { LocalStorageService } from '../../services';

interface QRCodeProps {
  url?: string;
  alt?: string;
}
const QRCode: FunctionComponent<QRCodeProps> = ({
  url = '',
  alt,
}: QRCodeProps) => {
  const projectId = LocalStorageService.getProjectId();
  url = projectId === null ? url : addProjectQueryStringToUrl(url, projectId);

  // do not add the sessionid on the group qr code
  if (!SettingsHelper.isGroupTraining()) {
    url = addSessionIdQueryStringToUrl(url);
  }

  const { isMediumWidth } = useViewport();

  const StyledQrImage = styled.div`
    grid-column: 1 / -1;
    grid-row: 2;
    max-width: 100%;
    max-height: 100%;
    align-self: flex-end;
    justify-self: center;
    z-index: -1;

    ${mediaMedium({
      gridColumn: '2 / 4',
      alignSelf: 'center',
    })}
  `;

  return (
    <StyledQrImage>
      <QRCodeSVG
        value={url}
        size={isMediumWidth ? 300 : 215}
        fgColor="#000000ff"
        bgColor="#ffffffff"
      />
    </StyledQrImage>
  );
};

export default QRCode;
