import constants from '../constants/Constants';
import { LocalStorageService } from '../services';

const addQueryStringToUrl = (url, key, value) => {
  if (!value || url.indexOf(key) > -1) {
    return url;
  }

  const urlQueryString = new URLSearchParams();
  urlQueryString.set(key, value);

  return `${url}${getQueryStringCombination(url)}${urlQueryString.toString()}`;
};

/* eslint-disable import/prefer-default-export */
export const addProjectQueryStringToUrl = (
  url: string,
  projectId: string,
): string => {
  return addQueryStringToUrl(
    url,
    constants.queryKeys.SANITY_PROJECT_KEY,
    projectId,
  );
};

export const addSessionIdQueryStringToUrl = (url: string): string => {
  const sessionId = LocalStorageService.getSessionId();
  return addQueryStringToUrl(
    url,
    constants.queryKeys.SESSION_ID_KEY,
    sessionId,
  );
};

export const addLanguageQueryStringToUrl = (
  url: string,
  languageCode: string,
): string => {
  return addQueryStringToUrl(
    url,
    constants.queryKeys.LANGUAGE_CODE_KEY,
    languageCode,
  );
};
/**
 *  Removes the protocol from the URL
 * (www. https:// http://) */
export const removeProtocolFromUrl = (url: string): string => {
  return url.replace(/(^\w+:|^)\/\//, '');
};

export const isValidExternalUrl = (url: string) => {
  let testUrl;
  try {
    testUrl = new URL(url);
  } catch (_) {
    return false;
  }
  return testUrl.protocol === 'http:' || testUrl.protocol === 'https:';
};

const getQueryStringCombination = (url: string) => {
  const querystringCombinationChar = url.includes('?') ? '&' : '?';
  return querystringCombinationChar;
};

/**
 * Making sure the Url is valid external url, or a string that starts with a '/', adds one if it's missing
 *
 * @param url a string to check
 * @returns the url, if it is a valid external url, else it will return a string starting with a '/',
 */
export const urlChecker = (url: string): string => {
  if (!url) {
    return url;
  }
  if (isValidExternalUrl(url)) {
    return url;
  }
  return url.charAt(0) === '/' ? url : `/${url}`;
};

export const getQuerystringParameter = (parameter: string): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get(parameter);
};

export const getCurrentUrl = () => {
  return window.location.origin;
};

export const isLocalhost = () => {
  const { hostname } = window.location;
  return hostname === 'localhost' || hostname === '127.0.0.1';
};
