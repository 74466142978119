import { FunctionComponent, useEffect, useState } from 'react';
import { WithFadeIn, WithFocus } from '../../components';
import { useSanityCourse } from '../../context';
import { RatingPageTemplate } from '../types/courseTypes';
import RatingPage from './RatingPage';

type PageProps = {
  template?: RatingPageTemplate;
};

const DynamicRatingPage: FunctionComponent<PageProps> = ({ template }) => {
  // undefined the first time, then set to the current page
  const [currentPage, setCurrentPage] = useState<RatingPageTemplate>(
    {} as RatingPageTemplate,
  );
  const courseContext = useSanityCourse();

  useEffect(() => {
    const tempPage =
      template ?? courseContext.getCurrentPage().ratingPageTemplate;

    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
    }
  }, []);

  if (currentPage === undefined) {
    return null;
  }

  const page: RatingPageTemplate = {
    ...currentPage,
  };
  return (
    <RatingPage
      headingText={page.headingText}
      subheadingText={page.subheadingText}
      image={page.image}
      showTimeLeftBar={page.showTimeLeftBar}
      oneStarRatingText={page.oneStarRatingText}
      twoStarRatingText={page.twoStarRatingText}
      threeStarRatingText={page.threeStarRatingText}
      fourStarRatingText={page.fourStarRatingText}
      fiveStarRatingText={page.fiveStarRatingText}
      custombutton={page.custombutton}
    />
  );
};

export default WithFadeIn(WithFocus(DynamicRatingPage));
