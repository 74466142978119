/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { PortableText } from '@portabletext/react';
import { Constants as constants } from '../../constants';
import { BasicQueryStrings } from '../../context/UserProvider';
import SettingsHelper from '../../helper/SettingsHelper';
import IconList, {
  IconListDataMap,
} from '../../pageTemplates/Templates/InfoPageImageBulletList';
import { ImageTypes } from '../../pageTemplates/Templates/InfoPageTypes';
import {
  AEDDraggablePadPageTemplate,
  AedBubbleTemplate,
  AedTemplateProps,
  ConnectedLearnersTemplate,
  DynamicDialoguePageTemplateProps,
  EmailReminderTemplate,
  EmergencyCallTemplateProps,
  FacilitatorPageWithTemplate,
  FacilitatorTextAndImageTemplate,
  FrontPageTemplate,
  ImageTemplate,
  InputPageTemplate,
  LearnerFrontPageTemplate,
  LearnerInputField,
  LearnerPageWithTemplate,
  LearnerRoleSplitTemplate,
  LearnerShowMyTeamTemplate,
  LearnerTextAndImageTemplate,
  PageWithTemplate,
  QuizPageTemplate,
  RatingPageTemplate,
  SanityBanner,
  SanityCard,
  SectionProps,
  SelectionTemplate,
  TeamUpTheLearnersTemplate,
  TextAndImageTemplate,
  TextAndImageTemplateDialog,
  TextAndImageTemplateDialogText,
  TrainingTemplate,
  clickableCardsTemplate,
} from '../../pageTemplates/types/courseTypes';
import type { DiplomaTemplate } from '../../pageTemplates/types/diplomaTemplateTypes';
import { SanityImage } from '../../pageTemplates/types/sanityTypes';
import { LocalStorageService } from '../../services';
import { DiplomaText } from '../../services/EmailService';
import * as defaultText from './defaultText';
import { getAudioSource, urlChecker } from './helpers';
import urlFor, { sanityRefToUrl } from './sanityAssetUrl';
import { Templates as SanityTemplates } from './sanityTypes';
import { CountDown } from './sanityTypes/countDown';
import {
  CommonNavigation,
  LearnerNavigation,
} from './sanityTypes/navigationTypes';
import { QueryString } from './sanityTypes/QueryStringType';
import * as SanityTextAndImgTypes from './sanityTypes/templates/textAndImageTemplate';
import { ClickableCard } from './sanityTypes/text/clickableCard';
import { cardNavigationTypes } from './types';

const wrapButton = (buttons) => {
  if (buttons === undefined) {
    return [];
  }
  // We had to change the property name because of Smartling.
  // As our old property name can't be easily changed, we have to support the old property name
  buttons.forEach((button) => {
    if (button.buttonType === undefined && button.type !== undefined) {
      button.buttonType = button.type;
    }
  });

  if (Array.isArray(buttons)) {
    return buttons;
  }

  return [...buttons];
};

const {
  ORGANIZATION_KEY,
  SHARE_LEVEL_KEY,
  TAG_KEY,
  DISTRIBUTION_KEY,
  START_OF_JOURNEY_KEY,
  SANITY_KEY,
  LANGUAGE_CODE_KEY,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
  SANITY_PROJECT_KEY,
} = constants.queryKeys;

const queryCodeToUrl = (
  rootUrl: string,
  keepQueryString?: boolean,
  queryStrings?: QueryString[],
  startOfJourneyString = 'QRCode',
): string => {
  let organization = '';
  let tag = '';
  let distribution = '';
  let shareLevel = '';
  let sanity = '';
  let language = '';
  let utmCampaign = '';
  let utmMedium = '';
  let utmSource = '';
  let projectId = '';
  let sessionId = '';
  //
  if (startOfJourneyString) {
    startOfJourneyString = `${START_OF_JOURNEY_KEY}=${startOfJourneyString}`;
  } else {
    startOfJourneyString = '';
  }

  if (keepQueryString) {
    organization = LocalStorageService.getOrganization()
      ? `&${ORGANIZATION_KEY}=${encodeURIComponent(
          LocalStorageService.getOrganization() ?? '',
        )}`
      : '';
    tag = LocalStorageService.getTag()
      ? `&${TAG_KEY}=${encodeURIComponent(LocalStorageService.getTag() ?? '')}`
      : '';
    distribution = LocalStorageService.getDistribution()
      ? `&${DISTRIBUTION_KEY}=${encodeURIComponent(
          LocalStorageService.getDistribution() ?? '',
        )}`
      : '';
    shareLevel = LocalStorageService.getShareLevel()
      ? `&${SHARE_LEVEL_KEY}=${encodeURIComponent(
          LocalStorageService.getShareLevel() ?? '',
        )}`
      : '';
    sanity = LocalStorageService.getSanity()
      ? `&${SANITY_KEY}=${encodeURIComponent(
          LocalStorageService.getSanity() ?? '',
        )}`
      : '';
    language = LocalStorageService.getLanguageCodeFromLocalStorage()
      ? `&${LANGUAGE_CODE_KEY}=${encodeURIComponent(
          LocalStorageService.getLanguageCodeFromLocalStorage() ?? '',
        )}`
      : '';

    utmCampaign = LocalStorageService.getUTMCampaign()
      ? `&${UTM_CAMPAIGN}=${encodeURIComponent(
          LocalStorageService.getUTMCampaign() ?? '',
        )}`
      : '';
    utmMedium = LocalStorageService.getUTMMedium()
      ? `&${UTM_MEDIUM}=${encodeURIComponent(
          LocalStorageService.getUTMMedium() ?? '',
        )}`
      : '';
    utmSource = LocalStorageService.getUTMSource()
      ? `&${UTM_SOURCE}=${encodeURIComponent(
          LocalStorageService.getUTMSource() ?? '',
        )}`
      : '';
    projectId = LocalStorageService.getProjectId()
      ? `&projectId=${LocalStorageService.getProjectId()}`
      : '';

    // do not add the sessionid on the group qr code
    if (!SettingsHelper.isGroupTraining()) {
      sessionId = LocalStorageService.getSessionId()
        ? `&sessionId=${LocalStorageService.getSessionId()}`
        : '';
    }
  }

  if (queryStrings !== undefined) {
    queryStrings.forEach((queryString) => {
      queryString.value = encodeURIComponent(queryString.value);
      switch (queryString.type) {
        case ORGANIZATION_KEY:
          organization = `&${ORGANIZATION_KEY}=${queryString.value}`;
          break;
        case TAG_KEY:
          organization = `&${TAG_KEY}=${queryString.value}`;
          break;
        case DISTRIBUTION_KEY:
          organization = `&${DISTRIBUTION_KEY}=${queryString.value}`;
          break;
        case SHARE_LEVEL_KEY:
          organization = `&${SHARE_LEVEL_KEY}=${queryString.value}`;
          break;
        case START_OF_JOURNEY_KEY:
          startOfJourneyString = `&${START_OF_JOURNEY_KEY}=${queryString.value}`;
          break;
        case SANITY_KEY:
          sanity = `&${SANITY_KEY}=${queryString.value}`;
          break;
        case LANGUAGE_CODE_KEY:
          language = `&${LANGUAGE_CODE_KEY}=${queryString.value}`;
          break;
        case UTM_CAMPAIGN:
          utmCampaign = `&${UTM_CAMPAIGN}=${queryString.value}`;
          break;
        case UTM_MEDIUM:
          utmMedium = `&${UTM_MEDIUM}=${queryString.value}`;
          break;
        case UTM_SOURCE:
          utmSource = `&${UTM_SOURCE}=${queryString.value}`;
          break;
        case SANITY_PROJECT_KEY:
          projectId = `&${SANITY_PROJECT_KEY}=${queryString.value}`;
          break;
        default:
          break;
      }
    });
  }

  const addedQuery = `${startOfJourneyString}${projectId}${organization}${tag}${distribution}${shareLevel}${sanity}${language}${utmCampaign}${utmMedium}${utmSource}${sessionId}`;
  const delimiter = addedQuery && addedQuery.length > 0 ? '?' : '';
  const url = `${rootUrl}${delimiter}${addedQuery}`;
  return url;
};

const createSanityImageFromSanityCustomImage = (image) => {
  if (image === undefined) {
    return {
      src: '',
      imgType: ImageTypes.FullWidth,
      alt: '',
      caption: '',
      lottie: null,
    } as SanityImage;
  }

  if (image.imgType === ImageTypes.Icon) {
    const img = {
      ...image.iconImage,
      imgType: image.imgType,
    } as SanityImage;

    img.src = urlFor(img);
    return img;
  }
  if (image.imgType === ImageTypes.FullWidth) {
    const img = {
      ...image.fullWidthImage,
      imgType: image.imgType,
    } as SanityImage;

    img.src = urlFor(img);
    return img;
  }
  if (image.imgType === ImageTypes.QRCode) {
    if (image.qrCode === undefined) {
      throw new Error(`image.qrCode === undefined`);
    }
    return {
      src: queryCodeToUrl(
        `${document.location.origin}`,
        image.qrCode.keepQueryStrings,
        image.qrCode.queryStrings,
      ),
      imgType: image.imgType,
    } as SanityImage;
  }
  if (image.imgType === ImageTypes.Lottie && image.lottie !== undefined) {
    return {
      ...image.lottie,
      imgType: image.imgType,
      lottie: {
        height: image.lottie.height,
        width: image.lottie.width,
        options: {
          loop: true,
          autoplay: true,
          path: image.lottie.src,
          rendererSettings: {},
        },
      },
    } as SanityImage;
  }
  return {
    ...image,
  } as SanityImage;
};

const mapBanner = (banner): SanityBanner | undefined => {
  if (!banner) return undefined;

  return {
    frontImage: createSanityImageFromSanityCustomImage(banner.frontImage),
    frontImageAlignment: banner.frontImageAlignment,
    backImage: createSanityImageFromSanityCustomImage(banner.backImage),
  };
};

export const createTextAndImageBody = (portableText, bulletPoints) => {
  const iconsList = bulletPoints?.map((bulletPoint) => {
    return {
      icon: {
        src: urlFor(bulletPoint.iconImage),
        alt: bulletPoint.iconImage.alt,
      },
      text: bulletPoint.text,
    } as IconListDataMap;
  });
  return (
    <>
      {bulletPoints && <IconList data={iconsList} />}
      {portableText && <PortableText value={portableText} />}
    </>
  );
};

const mapDialogBody = (
  dialog: SanityTextAndImgTypes.DialogText | undefined,
): TextAndImageTemplateDialogText | undefined => {
  if (!dialog) {
    return undefined;
  }
  return {
    text: dialog?.text && <PortableText value={dialog?.text} />,
    textAlignCenter:
      (dialog.textAlignment &&
        dialog.textAlignment === constants.textAlignment.CENTER) ||
      false,
    pointerAlignLeft:
      (dialog.pointerAlignment && dialog.pointerAlignment === 'left') || false,
  };
};
const mapCountDown = (countDown: CountDown) => {
  if (!countDown) return undefined;

  return {
    ...countDown,
    hasSkipButton: countDown.hasSkipButton ?? false,
    playText: countDown.playText || defaultText.countDown.buttonText.resume,
    pauseText: countDown.pauseText || defaultText.countDown.buttonText.pause,
  };
};

export const mapInputPageTemplate = (template: any): InputPageTemplate => {
  if (template === undefined) {
    return {} as InputPageTemplate;
  }
  return {
    text: {
      header: template.text?.header,
      header2: template.text?.header2,
      body: createTextAndImageBody(
        template?.text?.body,
        template?.text?.bulletPoints,
      ),
    },
    showTimeLeftBar: template.showTimeLeftBar,
    verticalButtons: template.verticalButtons,
    buttons: wrapButton(template.buttons),
    firebaseStorageCollection:
      template.firebaseStorageDestination?.collectionName,
    inputField: {
      label: template.inputField?.label,
      minimumCharsToValidate: template.inputField?.minimumCharsToValidate,
      placeholder: template.inputField?.placeholder,
      validationText: template.inputField?.validationText,
    },
  } as InputPageTemplate;
};

// used only in mapTextAndImagePages
const mapTextAndImagePagesDialog = (
  dialog: SanityTextAndImgTypes.TextAndImageTemplateDialog | undefined,
): TextAndImageTemplateDialog | undefined => {
  if (!dialog) {
    return undefined;
  }

  return {
    showInitialDialog: dialog.showInitialDialog,
    initialDialog: {
      ...mapDialogBody(dialog.initialDialog),
    },
    showAnswerDialog: dialog.showAnswerDialog,
    answerDialog: {
      ...mapDialogBody(dialog.answerDialog),
      button: dialog.answerDialog?.button,
    },
  };
};

function createTextAndImageTemplate(
  textAndImagePage:
    | SanityTemplates.TextAndImageTemplate
    | SanityTemplates.FacilitatorTextAndImageTemplate
    | SanityTemplates.LearnerTextAndImageTemplate,
) {
  return {
    enableInputField: textAndImagePage.enableInputField ?? false,
    enableDialogText: textAndImagePage.enableDialog,
    dialog: mapTextAndImagePagesDialog(textAndImagePage.dialog),
    text: {
      header: textAndImagePage.text?.header,
      header2: textAndImagePage.text?.header2,
      body: createTextAndImageBody(
        textAndImagePage?.text?.body,
        textAndImagePage?.text?.bulletPoints,
      ),
      footerText: textAndImagePage.text?.footerText,
    },
    image: createSanityImageFromSanityCustomImage(textAndImagePage?.image),
    showTimeLeftBar: textAndImagePage.showTimeLeftBar,
    verticalButtons: textAndImagePage.verticalButtons,
    buttons: wrapButton(textAndImagePage.buttons),
    inputField: textAndImagePage.inputField,
    enableAutoplay: textAndImagePage.enableAutoplay,
    countDown: mapCountDown(textAndImagePage.countDown),
    enableAudio: textAndImagePage.enableAudio ?? false,
  };
}

export const mapTextAndImageTemplates = (
  textAndImagePage:
    | SanityTemplates.TextAndImageTemplate
    | SanityTemplates.FacilitatorTextAndImageTemplate
    | SanityTemplates.LearnerTextAndImageTemplate
    | undefined,
):
  | TextAndImageTemplate
  | FacilitatorTextAndImageTemplate
  | LearnerTextAndImageTemplate => {
  if (!textAndImagePage) {
    return {} as TextAndImageTemplate;
  }

  const baseTemplate = createTextAndImageTemplate(textAndImagePage);

  if (
    SanityTextAndImgTypes.isFacilitatorTextAndImageTemplate(textAndImagePage)
  ) {
    return {
      ...baseTemplate,
      displayCompletedLearners:
        textAndImagePage.displayCompletedLearners ?? false,
      completedText: textAndImagePage.completedText,
    } as FacilitatorTextAndImageTemplate;
  }

  if (SanityTextAndImgTypes.isLearnerTextAndImageTemplate(textAndImagePage)) {
    return {
      ...baseTemplate,
      displayTeamName: textAndImagePage.displayTeamName,
    } as LearnerTextAndImageTemplate;
  }
  return baseTemplate as TextAndImageTemplate;
};

export const mapImageTemplate = (
  imagePage: SanityTemplates.ImageTemplate | undefined,
): ImageTemplate => {
  if (!imagePage) {
    return {} as ImageTemplate;
  }
  const baseTemplate = {
    image: createSanityImageFromSanityCustomImage(imagePage.image),
    verticalButtons: imagePage.verticalButtons,
    buttons: wrapButton(imagePage.buttons),
    enableAutoplay: imagePage.enableAutoplay,
    countDown: mapCountDown(imagePage.countDown),
    enableAudio: imagePage.enableAudio,
  } as ImageTemplate;

  return baseTemplate;
};

const mapConnectedLearnersTemplate = (
  connectedLearnersPage: any,
): ConnectedLearnersTemplate => {
  if (connectedLearnersPage === undefined) {
    return {} as ConnectedLearnersTemplate;
  }
  return {
    banner: mapBanner(connectedLearnersPage.banner),
    buttons: wrapButton(connectedLearnersPage.buttons),
    classroomCode: connectedLearnersPage.classroomCode,
    connectedLearners: connectedLearnersPage.connectedLearners,
    displayJoinLink: connectedLearnersPage.displayJoinLink,
    image: createSanityImageFromSanityCustomImage(connectedLearnersPage?.image),
    text: {
      header: connectedLearnersPage.text?.header,
      header2: connectedLearnersPage.text?.header2,
      body: createTextAndImageBody(
        connectedLearnersPage?.text?.body,
        connectedLearnersPage?.text?.bulletPoints,
      ),
      footerText: connectedLearnersPage.text?.footerText,
    },
  } as ConnectedLearnersTemplate;
};

const mapTeamUpTheLearnersTemplate = (
  teamUpTheLearnersPage: any,
): TeamUpTheLearnersTemplate => {
  if (teamUpTheLearnersPage === undefined) {
    return {} as TeamUpTheLearnersTemplate;
  }
  return {
    buttons: wrapButton(teamUpTheLearnersPage.buttons),
    image: createSanityImageFromSanityCustomImage(teamUpTheLearnersPage?.image),
    verticalButtons: teamUpTheLearnersPage.verticalButtons,
    enableAudio: teamUpTheLearnersPage.enableAudio,
    enableAutoplay: teamUpTheLearnersPage.enableAutoplay,
    text: {
      header: teamUpTheLearnersPage.text?.header,
      header2: teamUpTheLearnersPage.text?.header2,
      body: createTextAndImageBody(
        teamUpTheLearnersPage?.text?.body,
        teamUpTheLearnersPage?.text?.bulletPoints,
      ),
      footerText: teamUpTheLearnersPage.text?.footerText,
    },
    showTimeLeftBar: teamUpTheLearnersPage.showTimeLeftBar,
  } as TeamUpTheLearnersTemplate;
};

const mapRatingPageTemplate = (ratingPage: any): RatingPageTemplate => {
  if (ratingPage === undefined) {
    return {} as RatingPageTemplate;
  }
  return {
    ...ratingPage,
    image: createSanityImageFromSanityCustomImage(ratingPage.image),
    ratingIconsAltText: {
      // We are doing this to ensure the entries are in the correct order
      oneRatingIconAltText: ratingPage.ratingIconsAltText?.oneRatingIconAltText,
      twoRatingIconAltText: ratingPage.ratingIconsAltText?.twoRatingIconAltText,
      threeRatingIconAltText:
        ratingPage.ratingIconsAltText?.threeRatingIconAltText,
      fourRatingIconAltText:
        ratingPage.ratingIconsAltText?.fourRatingIconAltText,
      fiveRatingIconAltText:
        ratingPage.ratingIconsAltText?.fiveRatingIconAltText,
    },
    ratingIcons: {
      selectedIcon: createSanityImageFromSanityCustomImage(
        ratingPage.ratingIcons?.selectedIcon,
      ),
      unSelectedIcon: createSanityImageFromSanityCustomImage(
        ratingPage.ratingIcons?.unSelectedIcon,
      ),
    },
  } as RatingPageTemplate;
};

const mapDiplomaTexts = (texts: DiplomaText[]): DiplomaText[] | undefined => {
  if (!texts) {
    return undefined;
  }
  const mappedText = texts.map((text) => {
    // call to diploma API fail when addFieldToForm is false
    if (text?.formTextField?.addFieldToForm === false) {
      text.formTextField = undefined;
    }
    return text;
  });
  return mappedText;
};

const mapDiplomaPages = (diplomaPage: any): DiplomaTemplate => {
  if (diplomaPage === undefined) {
    return {} as DiplomaTemplate;
  }
  const diploma = {
    ...diplomaPage,
    diplomaForm: {
      ...diplomaPage?.diplomaForm,
    },
    successModal: {
      ...diplomaPage?.successModal,
      buttons: wrapButton(diplomaPage?.successModal?.buttons),
    },
    errorModal: {
      ...diplomaPage?.errorModal,
      buttons: wrapButton(diplomaPage?.errorModal?.buttons),
    },
    email: {
      subject: diplomaPage?.emailSubject,
      body: diplomaPage?.emailBody,
      company: diplomaPage?.emailCompany,
    },
    buttons: wrapButton(diplomaPage.buttons),
    text: {
      header: diplomaPage.text?.header,
      header2: diplomaPage.text?.header2,
      body: createTextAndImageBody(
        diplomaPage?.text?.body,
        diplomaPage?.text?.bulletPoints,
      ),
    },
    diplomaData: {
      qrCodes: diplomaPage.qrCodes,
      diplomaTexts: mapDiplomaTexts(diplomaPage.diplomaTexts),
      diplomaUrl: diplomaPage?.diplomaImage?.src,
    },
    receiveResearchCommunication: {
      displayReceiveResearchCommunicationCheckbox:
        diplomaPage.displayReceiveResearchCommunicationCheckbox,
      label: diplomaPage.receiveResearchCommunication?.label,
    },
  } as DiplomaTemplate;
  return diploma;
};

const mapCards = (cards: ClickableCard[]): SanityCard[] => {
  const mappedCards: SanityCard[] = cards.map((card) => {
    return {
      heading: card.heading ?? '',
      subHeading: card.subHeading ?? '',
      iconImage: card.iconImage ?? null,
      navigation: card.navigation ?? cardNavigationTypes.nextPage ?? '',
      url: card.url
        ? queryCodeToUrl(
            card.url,
            card.queryString?.keepQueryStrings,
            card.queryString?.queryStrings,
            '',
          )
        : '',
      queryString: card.queryString,
      openInNewTab: card.openInNewTab ?? false,
      cardClicked: function (event: any): void {
        throw new Error('Function not implemented.');
      },
    };
  });

  return mappedCards;
};

const mapClickableCardsTemplate = (
  clickableCardPage: SanityTemplates.ClickableCardsTemplate,
): clickableCardsTemplate => {
  if (
    clickableCardPage === undefined ||
    clickableCardPage.cards === undefined
  ) {
    return {} as clickableCardsTemplate;
  }
  return {
    ...clickableCardPage,
    text: {
      header: clickableCardPage.text?.header,
      body: createTextAndImageBody(
        clickableCardPage.text?.body,
        clickableCardPage.text?.bulletPoints,
      ),
      footerText: clickableCardPage.text?.footerText,
    },
    banner: mapBanner(clickableCardPage.banner),
    cards: mapCards(clickableCardPage.cards),
    showVersionNumber: clickableCardPage.showVersionNumber ?? false,
  } as clickableCardsTemplate;
};

export const mapFrontPageTemplate = (template: any): FrontPageTemplate => {
  if (template === undefined) {
    return {} as FrontPageTemplate;
  }
  return {
    classroomText: {
      classroomUrl: template.joinClassroomText?.classroomUrl,
      classroomcodeText: template.joinClassroomText?.classroomcodeText,
      descriptionText: template.joinClassroomText?.descriptionText,
      joinedText: template.joinClassroomText?.joinedText,
    },
    text: {
      header: template.text?.header,
      body: createTextAndImageBody(
        template.text?.body,
        template.text?.bulletPoints,
      ),
      footerText: template.text?.footerText,
    },
    image: createSanityImageFromSanityCustomImage(template.image),
    banner: mapBanner(template.banner),
    showTimeLeftBar: template.showTimeLeftBar,
    verticalButtons: template.verticalButtons,
    showVersionNumber: template.showVersionNumber,
    buttons: wrapButton(template.buttons),
    enableLanguageSelectionField:
      template.enableLanguageSelectionField ?? false,
    languageModal: {
      headingText: template.languageModal?.headingText,
      buttonText: template.languageModal?.buttonText,
    },
  } as FrontPageTemplate;
};

const mapLearnerFrontPageTemplate = (
  template: any,
): LearnerFrontPageTemplate => {
  if (template === undefined) {
    return {} as LearnerFrontPageTemplate;
  }
  const inputField: LearnerInputField = {
    ...{
      placeholder: 'Classroom code',
      label: 'Classroom code',
      backendCodeIsNotValidErrorMessage: 'Code is not valid',
      backendGeneralError:
        'An error happend while creating a code. Please try again',
      validationErrorMessage: 'Code is not valid',
    },
    ...template.inputField,
  };

  return {
    classroomText: {
      classroomUrl: template.joinClassroomText?.classroomUrl,
      classroomcodeText: template.joinClassroomText?.classroomcodeText,
      descriptionText: template.joinClassroomText?.descriptionText,
      joinedText: template.joinClassroomText?.joinedText,
    },
    text: {
      header: template.text?.header,
      body: createTextAndImageBody(
        template.text?.body,
        template.text?.bulletPoints,
      ),
      footerText: template.text?.footerText,
    },
    image: createSanityImageFromSanityCustomImage(template.image),
    banner: mapBanner(template.banner),
    showTimeLeftBar: template.showTimeLeftBar,
    verticalButtons: template.verticalButtons,
    showVersionNumber: template.showVersionNumber,
    buttons: wrapButton(template.buttons),
    inputField,
  } as LearnerFrontPageTemplate;
};

export const mapEmergencyCallTemplate = (
  template: any,
): EmergencyCallTemplateProps => {
  if (template === undefined) {
    return {} as EmergencyCallTemplateProps;
  }
  return {
    ...template,
    numberToCall: template.numberToCall,
    whyTrainButton: template.whyTrainButton,
    headerText: template.headerText,
    practiceCallingText: template.practiceCallingText,
    whyTrainModalHeaderText: template.whyTrainModalHeaderText,
    whyTrainModalButtonText: template.whyTrainModalButtonText,
    whyTrainModalBodyText: template.whyTrainModalBodyText && (
      <PortableText value={template.whyTrainModalBodyText} />
    ),
    showCall911Modal: template.showCall911Modal,
    call911ModalImage: createSanityImageFromSanityCustomImage(
      template.emergencyCallModal?.emergencyCallModalHeaderImage,
    ),
    call911ModalHeaderText:
      template.emergencyCallModal?.emergencyCallModalHeaderText,
    call911ModalBodyText:
      template.emergencyCallModal?.emergencyCallModalBodyText,
    call911ModalPrimaryButton:
      template.emergencyCallModal?.emergencyCallModalPrimaryButton,
    call911ModalSecondaryButton:
      template.emergencyCallModal?.emergencyCallModalSecondaryButton,
    bottomText: template.bottomText,
    showBottomButton: template.showBottomButton,
    showBottomText: template.showBottomText,
  } as unknown as EmergencyCallTemplateProps;
};

const mapQuizPageTemplate = (
  template: SanityTemplates.QuizPageTemplate,
): QuizPageTemplate => {
  if (template === undefined) {
    return {} as QuizPageTemplate;
  }
  return {
    showTimeLeftBar: template.showTimeLeftBar ?? true,
    displayTeamName: template.displayTeamName ?? false,
    answerExplanation: template.answerExplanation ?? '',
    answerOptions: template.answerOptions ?? [
      { isCorrect: true, answerString: '' },
    ],
    isSurvey: template.isSurvey ?? false,
    question: template.question ?? '',
    quizAnswerLabel: {
      correctAnswerLabelText:
        template.quizAnswerLabel?.correctAnswerLabelText ?? '',
      selectAnswerLabelText:
        template.quizAnswerLabel?.selectAnswerLabelText ?? '',
      wrongAnswerLabelText:
        template.quizAnswerLabel?.wrongAnswerLabelText ?? '',
    },
    quizButtonText: template.quizButtonText,
    image: createSanityImageFromSanityCustomImage(template.image),
  };
};

export const mapDialogPageTemplate = (
  template: any,
): DynamicDialoguePageTemplateProps => {
  if (template === undefined) {
    return {} as DynamicDialoguePageTemplateProps;
  }
  return {
    buttons: {
      pauseButtonText: template.buttons?.pausePracticeButtonText,
      resumeButtonText: template.buttons?.continuePracticeButtonText,
    },
    showTimeLeftBar: template.showTimeLeftBar,
    continueCourseModal: {
      body: template.continueCourseModal?.body && (
        <PortableText value={template.continueCourseModal?.body} />
      ),
      continuePracticeButtonText:
        template.continueCourseModal?.continueButtonText,
      headerText: template.continueCourseModal?.headerText,
      startOverButtonText: template.continueCourseModal?.startOverButtonText,
      startOverUrl: urlChecker(template.continueCourseModal?.url),
    },

    enableAutoplay: template.enableAutoplay,
    countDown: mapCountDown(template.countDown),
    text: template.headerText,
    replyBox: {
      button: template.dialogPageTemplateContent?.replybuttonText,
      text: template.dialogPageTemplateContent?.replyboxText,
    },
    speechbubbleContent: template.dialogPageTemplateContent
      ?.speechbubbleContent && (
      <PortableText
        value={template.dialogPageTemplateContent?.speechbubbleContent}
      />
    ),
    mainImg: {
      alt: template.mainImage?.customSizeImage?.alt,
      height: template.mainImage?.customSizeImage?.height,
      src: urlFor(template.mainImage),
      imgType: template.mainImage?.imgType,
    },
  } as unknown as DynamicDialoguePageTemplateProps;
};

export const mapTrainingTemplate = (template: any): TrainingTemplate => {
  if (template === undefined) {
    return {} as TrainingTemplate;
  }
  return {
    ...template,
    trainingPage: {
      ...template.trainingPage,
      trainingIntro: {
        ...template.trainingPage?.trainingIntro,
        enableCountDown:
          template.trainingPage?.trainingIntro?.enableCountDown ?? false,
      },
    },
  } as TrainingTemplate;
};

export const mapAedTemplate = (template: any): AedTemplateProps => {
  if (template === undefined) {
    return {} as AedTemplateProps;
  }
  return {
    continueCourseModal: {
      body: template.continueCourseModal?.body && (
        <PortableText value={template.continueCourseModal?.body} />
      ),
      continuePracticeButtonText:
        template.continueCourseModal?.continueButtonText,
      headerText: template.continueCourseModal?.headerText,
      startOverButtonText: template.continueCourseModal?.startOverButtonText,
      startOverUrl: urlChecker(template.continueCourseModal?.url),
    },
    image: createSanityImageFromSanityCustomImage(template.image),
    shockButton: {
      alt: template.shockButton?.alt,
      src:
        urlFor(template.shockButton) ||
        sanityRefToUrl(constants.aedTemplateDefaultImages.shockButtonSrc),
    },
    turnOnButton: {
      alt: template.turnOnButton?.alt,
      src:
        urlFor(template.turnOnButton) ||
        sanityRefToUrl(constants.aedTemplateDefaultImages.turnOnButtonSrc),
    },
    dialog:
      template.hasDialog === true
        ? {
            text: template.dialogText?.text && (
              <PortableText value={template.dialogText?.text} />
            ),
            textAlignCenter:
              template.dialogText === undefined ||
              template.dialogText?.textAlignment === undefined
                ? true
                : template.dialogText?.textAlignment &&
                  template.dialogText.textAlignment ===
                    constants.textAlignment.CENTER,
          }
        : null,
    volumeDialog: {
      primaryButtonText: template.iconDialog?.primaryButtonText,
      title: template.iconDialog?.title,
      body: template.iconDialog?.body && (
        <PortableText value={template.iconDialog?.body} />
      ),
      image: {
        alt: template.volumeDialog?.image?.customSizeImage?.alt,
        height: template.volumeDialog?.image?.customSizeImage?.height,
        src: urlFor(template.volumeDialog?.image?.customSizeImage),
        imgType: template.volumeDialog?.image?.imgType,
      },
    },
    text:
      template.hasDialog === false || template.hasDialog === undefined
        ? {
            header: template.text?.header,
            body: template.text?.body && (
              <PortableText value={template.text?.body} />
            ),
            footerText: template.text?.footerText,
          }
        : null,
    aedState: template.aedState,
    displayTeamName: template.displayTeamName,
    showTimeLeftBar: template.showTimeLeftBar,
  } as unknown as AedTemplateProps;
};
const mapAedDraggableTemplate = (
  template: any,
): AEDDraggablePadPageTemplate => {
  if (template === undefined) {
    return {} as AEDDraggablePadPageTemplate;
  }
  return {
    displayTeamName: template.displayTeamName,
    showTimeLeftBar: template.showTimeLeftBar,
    image: {
      imgType: ImageTypes.FullWidth,
      src:
        urlFor(template.image) ||
        sanityRefToUrl(constants.aedPadPlacementDefaultImages.aedPersonSrc),
    },
    correctImage: {
      imgType: ImageTypes.FullWidth,
      src:
        urlFor(template.correctImage) ||
        sanityRefToUrl(
          constants.aedPadPlacementDefaultImages.aedPersonCorrectSrc,
        ),
    },
    aedLeftPad: {
      src:
        urlFor(template.leftPadImage) ||
        sanityRefToUrl(constants.aedPadPlacementDefaultImages.leftPadSrc),
    },
    aedRightPad: {
      imgType: ImageTypes.FullWidth,
      src:
        urlFor(template.rightPadImage) ||
        sanityRefToUrl(constants.aedPadPlacementDefaultImages.rightPadSrc),
    },

    aedPadPlacement: template.aedPadPlacement,
    aedPadCorrectPosition: template.aedPadCorrectPosition,
    aedPadInCorrectPosition: template.aedPadInCorrectPosition,
    aedPadHelp: template.aedPadHelp,
    aedPadHelpModal: template.aedPadHelpModal,
    aedPadPlacementValues: {
      ...template.aedPadPlacementValues,
      rightPadAreaImage: {
        imgType: ImageTypes.FullWidth,
        src:
          urlFor(template.aedPadPlacementValues?.rightPadAreaImage) ||
          sanityRefToUrl(
            constants.aedPadPlacementDefaultImages.rightPadAreaSrc,
          ),
      },
      leftPadAreaImage: {
        imgType: ImageTypes.FullWidth,
        src:
          urlFor(template.aedPadPlacementValues?.leftPadAreaImage) ||
          sanityRefToUrl(constants.aedPadPlacementDefaultImages.leftPadAreaSrc),
      },
    },
    useOnlyOnePad: template.useOnlyOnePad,
    enforceStrictPadPlacement: template.enforceStrictPadPlacement,
    rightPadAlternateImageGroup: {
      ...template.rightPadAlternateImageGroup,
      alternateRightPadImage:
        template.rightPadAlternateImageGroup?.alternateRightPadImage?.src ||
        sanityRefToUrl(
          constants.aedPadPlacementDefaultImages.rightPadAlternateSrc,
        ),
      enableRightPadAlternateImage: template.enableRightPadAlternateImage,
    },
  } as unknown as AEDDraggablePadPageTemplate;
};

const mapAedBubbleTemplate = (template: any): AedBubbleTemplate => {
  if (template === undefined) {
    return {} as AedBubbleTemplate;
  }
  return {
    image:
      template.fullWidthImage === undefined
        ? undefined
        : {
            imgType: ImageTypes.FullWidth,
            alt: template.fullWidthImage?.alt,
            caption: template.fullWidthImage?.caption,
            src: urlFor(template.fullWidthImage),
          },
    dialogText: {
      text: template.dialogText?.text && (
        <PortableText value={template.dialogText?.text} />
      ),
      textAlignCenter:
        template.dialogText?.textAlignment &&
        template.dialogText.textAlignment === constants.textAlignment.CENTER,
    },
    text: {
      header: template.text?.header,
      header2: template.text?.header2,
      body: template.text?.body && <PortableText value={template.text?.body} />,
    },
    continueCourseModal: {
      body: template.continueCourseModal?.body && (
        <PortableText value={template.continueCourseModal?.body} />
      ),
      continuePracticeButtonText:
        template.continueCourseModal?.continueButtonText,
      headerText: template.continueCourseModal?.headerText,
      startOverButtonText: template.continueCourseModal?.startOverButtonText,
      startOverUrl: urlChecker(template.continueCourseModal?.url),
    },
    lottie:
      template.lottie === undefined
        ? undefined
        : {
            width: template.lottie.width,
            height: template.lottie.height,
            options: {
              loop: true,
              autoplay: true,
              path: template.lottie.src,
              rendererSettings: {},
            },
          },
  } as unknown as AedBubbleTemplate;
};

const mapLearnerRoleSplitTemplate = (
  template: any,
): LearnerRoleSplitTemplate => {
  if (template === undefined) {
    return {} as LearnerRoleSplitTemplate;
  }
  const roleA = { ...template.roleA };
  const roleB = { ...template.roleB };

  const res = {
    roleA: {
      ...roleA,
      textAndImageTemplate: mapTextAndImageTemplates(
        roleA?.textAndImageTemplate,
      ),
      videoPageTemplate: roleA?.videoPageTemplate,
      quizPageTemplate: mapQuizPageTemplate(roleA?.quizPageTemplate),
      emergencyCallTemplate: mapEmergencyCallTemplate(
        roleA?.emergencyCallTemplate,
      ),
      ratingPageTemplate: mapRatingPageTemplate(roleA?.ratingPageTemplate),
      aedDraggablePadPageTemplate: mapAedDraggableTemplate(
        roleA?.aedDraggablePadPageTemplate,
      ),
      trainingTemplate: mapTrainingTemplate(roleA?.trainingTemplate),
      aedTemplate: mapAedTemplate(roleA?.aedTemplate),
    },
    roleB: {
      ...roleB,
      textAndImageTemplate: mapTextAndImageTemplates(
        roleB?.textAndImageTemplate,
      ),
      videoPageTemplate: roleB?.videoPageTemplate,
      quizPageTemplate: mapQuizPageTemplate(roleB.quizPageTemplate),
      emergencyCallTemplate: mapEmergencyCallTemplate(
        roleB?.emergencyCallTemplate,
      ),
      ratingPageTemplate: mapRatingPageTemplate(roleB?.ratingPageTemplate),
      aedDraggablePadPageTemplate: mapAedDraggableTemplate(
        roleB?.aedDraggablePadPageTemplate,
      ),
      trainingTemplate: mapTrainingTemplate(roleB?.trainingTemplate),
      aedTemplate: mapAedTemplate(roleB?.aedTemplate),
    },
  } as unknown as LearnerRoleSplitTemplate;
  return res;
};

const mapEmailReminderTemplate = (template: any): EmailReminderTemplate => {
  if (template === undefined) {
    return {} as EmailReminderTemplate;
  }
  return {
    ...template,
    image: createSanityImageFromSanityCustomImage(template.image),
  } as unknown as EmailReminderTemplate;
};

const mapLearnerShowMyTemplate = (template: any): LearnerShowMyTeamTemplate => {
  if (template !== undefined) {
    return {
      ...template,
      image: createSanityImageFromSanityCustomImage(template.image),
      text: {
        header: template.text?.header,
        header2: template.text?.header2,
        body: template.text?.body && (
          <PortableText value={template.text?.body} />
        ),
        footerText: template.text?.footerText,
      },
    } as unknown as LearnerShowMyTeamTemplate;
  }
  return {} as LearnerShowMyTeamTemplate;
};

const mapQueryStringType = (queryType: string): keyof BasicQueryStrings => {
  switch (queryType) {
    case 'dist':
      return 'distribution';
    case 'org':
      return 'organization';
    case 'start':
      return 'startOfJourney';
    case 'tag':
      return 'tag';
    case 'utm_campaign':
      return 'utm_campaign';
    case 'utm_medium':
      return 'utm_medium';
    case 'utm_source':
      return 'utm_source';
    default:
      break;
  }
};

const mapSelectionTemplate = (template: any): SelectionTemplate => {
  if (template === undefined) {
    return {} as SelectionTemplate;
  }
  return {
    ...template,
    buttons: wrapButton(template.buttons),
    queryStringType:
      mapQueryStringType(template.queryStringType) ?? 'utm_campaign',
  } as unknown as SelectionTemplate;
};

export const mapSection = (data: any): SectionProps => {
  if (data === undefined) {
    return {} as SectionProps;
  }
  const translatedData =
    data.translation?.[0]?.translations?.[0]?.value ?? data;
  return {
    continueCourseModal: {
      body: translatedData.continueCourseModal?.body && (
        <PortableText value={translatedData.continueCourseModal?.body} />
      ),
      continuePracticeButtonText:
        translatedData.continueCourseModal?.continueButtonText,
      headerText: translatedData.continueCourseModal?.headerText,
      startOverButtonText:
        translatedData.continueCourseModal?.startOverButtonText,
      startOverUrl: translatedData.continueCourseModal?.url,
    },
    title: translatedData.title,
  } as unknown as SectionProps;
};

export const mapPageWithTemplate = (pageWithTemplate) => {
  if (!pageWithTemplate) {
    return undefined;
  }
  const tempUrl: string = !pageWithTemplate.url // Choosing url in this prioritized order:
    ? pageWithTemplate.slug?.current // navigationData.url > navigationData.slug.current > navigationData.page.url
      ? pageWithTemplate.slug.current
      : pageWithTemplate.page.url
        ? pageWithTemplate.page.url
        : pageWithTemplate.title
    : pageWithTemplate.url;
  if (tempUrl === undefined) {
    throw new Error(
      `mapPageWithTemplate:navigation _id:(${pageWithTemplate._id}) has no valid URL (title, slug or Url) fix within Sanity`,
    );
  }

  try {
    const mappedTemplate = {
      url: urlChecker(tempUrl),
      id: pageWithTemplate._id,
      timeToComplete: pageWithTemplate.page?.timeToComplete
        ? pageWithTemplate.page.timeToComplete
        : pageWithTemplate.timeToComplete,
      audio: getAudioSource([
        ...(pageWithTemplate.page?.sounds || []),
        ...(pageWithTemplate.sounds || []),
      ]),
      template: pageWithTemplate.template,
      textAndImageTemplate: mapTextAndImageTemplates(
        pageWithTemplate.textAndImageTemplate,
      ),
      inputTemplate: mapInputPageTemplate(pageWithTemplate.inputTemplate),
      frontPageTemplate: mapFrontPageTemplate(
        pageWithTemplate.frontPageTemplate,
      ),
      videoPageTemplate: pageWithTemplate.videoPageTemplate,
      selectionTemplate: mapSelectionTemplate(
        pageWithTemplate.selectionTemplate,
      ),
      quizPageTemplate: mapQuizPageTemplate(pageWithTemplate.quizPageTemplate),
      emergencyCallTemplate: mapEmergencyCallTemplate(
        pageWithTemplate.emergencyCallTemplate,
      ),
      ratingPageTemplate: mapRatingPageTemplate(
        pageWithTemplate.ratingPageTemplate,
      ),
      trainingTemplate: mapTrainingTemplate(pageWithTemplate.trainingTemplate),
      dialogPageTemplate: mapDialogPageTemplate(
        pageWithTemplate.dialogPageTemplate,
      ),
      diplomaTemplate: mapDiplomaPages(pageWithTemplate.diplomaTemplate),
      aedBubbleTemplate: mapAedBubbleTemplate(
        pageWithTemplate.aedBubbleTemplate,
      ),
      aedDraggablePadPageTemplate: mapAedDraggableTemplate(
        pageWithTemplate.aedDraggablePadPageTemplate,
      ),
      aedTemplate: mapAedTemplate(pageWithTemplate.aedTemplate),
      section: mapSection(pageWithTemplate.section),
      emailReminderTemplate: mapEmailReminderTemplate(
        pageWithTemplate.emailReminderTemplate,
      ),
      clickableCardsTemplate: mapClickableCardsTemplate(
        pageWithTemplate.clickableCardsTemplate,
      ),
    } as PageWithTemplate;

    return mappedTemplate;
  } catch (error: any) {
    error.message = `${pageWithTemplate._id}, title: ${pageWithTemplate.title} : ${error?.message}`;
    throw error;
  }
};

export const mapLearnerNavigation = (
  url: string,
  learnerNavigation: LearnerNavigation,
): LearnerPageWithTemplate | undefined => {
  if (!learnerNavigation) {
    return undefined;
  }
  try {
    const mappedTemplate = {
      id: learnerNavigation._id,
      _id: learnerNavigation._id,
      title: learnerNavigation.title,
      url: urlChecker(url),
      timeToComplete: learnerNavigation.timeToComplete,
      audio: getAudioSource([...(learnerNavigation.sounds || [])]),
      template: learnerNavigation.template,
      textAndImageTemplate: mapTextAndImageTemplates(
        learnerNavigation.textAndImageTemplate,
      ),
      inputTemplate: mapInputPageTemplate(learnerNavigation.inputTemplate),
      diplomaTemplate: mapDiplomaPages(learnerNavigation.diplomaTemplate),
      frontPageTemplate: mapFrontPageTemplate(
        learnerNavigation.frontPageTemplate,
      ),
      videoPageTemplate: learnerNavigation.videoPageTemplate,
      quizPageTemplate: mapQuizPageTemplate(learnerNavigation.quizPageTemplate),
      emergencyCallTemplate: mapEmergencyCallTemplate(
        learnerNavigation.emergencyCallTemplate,
      ),
      ratingPageTemplate: mapRatingPageTemplate(
        learnerNavigation.ratingPageTemplate,
      ),
      aedDraggablePadPageTemplate: mapAedDraggableTemplate(
        learnerNavigation.aedDraggablePadPageTemplate,
      ),
      trainingTemplate: mapTrainingTemplate(learnerNavigation.trainingTemplate),
      dialogPageTemplate: mapDialogPageTemplate(
        learnerNavigation.dialogPageTemplate,
      ),
      aedBubbleTemplate: mapAedBubbleTemplate(
        learnerNavigation.aedBubbleTemplate,
      ),
      learnerRoleSplitTemplate: mapLearnerRoleSplitTemplate(
        learnerNavigation.learnerRoleSplitTemplate,
      ),
      aedTemplate: mapAedTemplate(learnerNavigation.aedTemplate),
      section: mapSection(learnerNavigation.section),
      learnerShowMyTeamTemplate: mapLearnerShowMyTemplate(
        learnerNavigation.learnerShowMyTeamTemplate,
      ),
      learnerFrontPageTemplate: mapLearnerFrontPageTemplate(
        learnerNavigation.learnerFrontPageTemplate,
      ),
    } as LearnerPageWithTemplate;
    return mappedTemplate;
  } catch (error: any) {
    error.message = `${learnerNavigation._id}, title: ${learnerNavigation.title} : ${error?.message}`;
    throw error;
  }
};

export const mapFacilitatorPageWithTemplate = (
  url: string,
  pageWithTemplate,
) => {
  if (!pageWithTemplate) {
    return undefined;
  }

  try {
    const mappedTemplate = {
      url: urlChecker(url),
      timeToComplete: pageWithTemplate.page?.timeToComplete
        ? pageWithTemplate.page.timeToComplete
        : pageWithTemplate.timeToComplete,
      audio: getAudioSource([
        ...(pageWithTemplate.page?.sounds || []),
        ...(pageWithTemplate.sounds || []),
      ]),
      template: pageWithTemplate.template,
      textAndImageTemplate: mapTextAndImageTemplates(
        pageWithTemplate.textAndImageTemplate,
      ),
      imageTemplate: mapImageTemplate(pageWithTemplate.imageTemplate),
      inputTemplate: mapInputPageTemplate(pageWithTemplate.inputTemplate),
      diplomaTemplate: mapDiplomaPages(pageWithTemplate.diplomaTemplate),
      frontPageTemplate: mapFrontPageTemplate(
        pageWithTemplate.frontPageTemplate,
      ),
      videoPageTemplate: pageWithTemplate.videoPageTemplate,
      quizPageTemplate: mapQuizPageTemplate(pageWithTemplate.quizPageTemplate),
      emergencyCallTemplate: mapEmergencyCallTemplate(
        pageWithTemplate.emergencyCallTemplate,
      ),
      ratingPageTemplate: mapRatingPageTemplate(
        pageWithTemplate.ratingPageTemplate,
      ),
      aedDraggablePadPageTemplate: pageWithTemplate.aedDraggablePadPageTemplate,
      trainingTemplate: mapTrainingTemplate(pageWithTemplate.trainingTemplate),
      dialogPageTemplate: mapDialogPageTemplate(
        pageWithTemplate.dialogPageTemplate,
      ),
      aedBubbleTemplate: mapAedBubbleTemplate(
        pageWithTemplate.aedBubbleTemplate,
      ),
      teamUpTheLearnersTemplate: mapTeamUpTheLearnersTemplate(
        pageWithTemplate.teamUpTheLearnersTemplate,
      ),
      aedTemplate: mapAedTemplate(pageWithTemplate.aedTemplate),
      section: mapSection(pageWithTemplate.section),
      connectedLearnersTemplate: mapConnectedLearnersTemplate(
        pageWithTemplate.connectedLearnersTemplate,
      ),
    } as FacilitatorPageWithTemplate;

    return mappedTemplate;
  } catch (error: any) {
    error.message = `${pageWithTemplate._id}, title: ${pageWithTemplate.title} : ${error?.message}`;
    throw error;
  }
};

export const mapCommonNavigation = (
  url: string,
  commonNavigation: CommonNavigation,
): LearnerPageWithTemplate | FacilitatorPageWithTemplate | undefined => {
  if (!commonNavigation) {
    return undefined;
  }
  try {
    if (window.revivr.isLearner) {
      return {
        id: commonNavigation._id,
        _id: commonNavigation._id,
        title: commonNavigation.title,
        url: urlChecker(url),
        timeToComplete: 5,
        audio: undefined,
        template: commonNavigation.template._type,
        [commonNavigation.template._type]: commonNavigation.template,
        section: mapSection(commonNavigation.section),
      } as LearnerPageWithTemplate;
    }
    // Facilitator
    return {
      url: urlChecker(url),
      timeToComplete: 5,
      audio: undefined,
      template: commonNavigation.template._type,
      [commonNavigation.template._type]: commonNavigation.template,
      section: mapSection(commonNavigation.section),
    } as FacilitatorPageWithTemplate;
  } catch (error: any) {
    error.message = `${commonNavigation._id}, title: ${commonNavigation.title} : ${error?.message}`;
    throw error;
  }
};
