/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from 'react';
import { useSanityCourse } from '../context';
import focusFirstTag from './UseFocus';

const WithFocus = (WrappedComponent) => {
  const componentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  const NewComponent = (props) => {
    const courseContext = useSanityCourse();

    useEffect(() => {
      const sanityTemplate = courseContext.getCurrentPage()?.template ?? null;
      focusFirstTag(componentName, sanityTemplate);
    }, []);

    return <WrappedComponent {...props} />;
  };

  return NewComponent;
};

export default WithFocus;
