import { FunctionComponent, useEffect, useState } from 'react';
import WelcomeImage from '../../assets/images/image_welcome.png';
import { WithFocus } from '../../components';
import LifeButton from '../../components/Button';
import {
  ClassroomCodeAndUrl,
  TimeLeftWithText,
} from '../../components/TopBars';
import WithFadeIn from '../../components/WithFadeIn';
import { useFirebase } from '../../context';
import { DisplayTeamName } from '../../features/groupRevivr/components';
import { useNavigation } from '../../hooks';
import {
  Img,
  PageGridStyle,
  ProgressbarContainer,
} from '../Templates/InfoPageStyles';
import { HeaderImage } from '../Templates/SimulationPageStyles';
import { RatingPageTemplate } from '../types/courseTypes';
import { SanityButton, SanityImage } from '../types/sanityTypes';
import Rating from './ConfidenceRating';
import {
  BottomContainer,
  ImageContainer,
  MainContentContainer,
} from './RatingPage.styles';

type ImagePartProps = {
  image?: SanityImage;
};

const RatingImagePart: FunctionComponent<ImagePartProps> = ({ image }) => {
  return (
    <ImageContainer>
      {image?.src ? (
        <Img
          src={image.src}
          alt={image.alt || 'Rating page image'}
          imgType={image.imgType}
        />
      ) : (
        <HeaderImage
          src={WelcomeImage}
          maxWidth="100%"
          maxHeight="235px"
          alt={image?.alt || 'Woman performing compressions on man'}
        />
      )}
    </ImageContainer>
  );
};

const Button: FunctionComponent<{
  customButton: SanityButton;
  confidenceRating: number;
  onSanityButtonClicked: () => void;
}> = ({ customButton, confidenceRating, onSanityButtonClicked }) => {
  const { storeGAEvent } = useFirebase();

  if (!customButton) {
    return;
  }
  return (
    <LifeButton
      style={{ width: '100%' }}
      variant={customButton.buttonType}
      onClick={() => {
        storeGAEvent({
          category: 'Confidence Rating',
          action: 'Confidence Selected',
          label: window.location.pathname,
          value: confidenceRating,
        });
        onSanityButtonClicked();
      }}
    >
      {customButton.text}
    </LifeButton>
  );
};

const RatingPage: FunctionComponent<RatingPageTemplate> = ({
  custombutton,
  displayTeamName,
  fiveStarRatingText,
  fourStarRatingText,
  headingText,
  image,
  oneStarRatingText,
  ratingIcons,
  ratingIconsAltText,
  showTimeLeftBar,
  subheadingText,
  threeStarRatingText,
  twoStarRatingText,
}) => {
  const [confidenceRating, setConfidenceRating] = useState(0);
  const [ratingText, setRatingText] = useState<string>(headingText ?? '');
  const navigationContext = useNavigation();

  const handleRating = (rating: number) => {
    setConfidenceRating(rating);
  };
  useEffect(() => {
    if (confidenceRating === 0) {
      setRatingText(headingText);
    } else if (confidenceRating === 1) {
      setRatingText(oneStarRatingText);
    } else if (confidenceRating === 2) {
      setRatingText(twoStarRatingText);
    } else if (confidenceRating === 3) {
      setRatingText(threeStarRatingText);
    } else if (confidenceRating === 4) {
      setRatingText(fourStarRatingText);
    } else if (confidenceRating === 5) {
      setRatingText(fiveStarRatingText);
    }
  }, [
    confidenceRating,
    headingText,
    oneStarRatingText,
    twoStarRatingText,
    threeStarRatingText,
    fourStarRatingText,
    fiveStarRatingText,
  ]);

  const onSanityButtonClicked = () => {
    if (!custombutton) {
      return;
    }
    if (custombutton.navigation === 'nextPage') {
      navigationContext?.navigateToNextPageWithSleepCheck();
      return;
    }
    if (custombutton.navigation === 'previousPage') {
      navigationContext?.navigateToPreviousPage();
    }
    if (custombutton.navigation === 'urlPage' && custombutton.url) {
      navigationContext?.navigateToUrl(custombutton.url);
    }
  };

  if (!navigationContext) {
    return null;
  }

  return (
    <PageGridStyle>
      <ClassroomCodeAndUrl />
      <DisplayTeamName enabled={displayTeamName} />
      <ProgressbarContainer>
        <TimeLeftWithText enabled={showTimeLeftBar} />
      </ProgressbarContainer>
      <MainContentContainer>
        <RatingImagePart image={image} />
        <Rating
          altText={ratingIconsAltText}
          mainText={ratingText}
          subText={subheadingText}
          onRatingClick={handleRating}
          ratingIcons={ratingIcons}
        />
      </MainContentContainer>
      <BottomContainer role="nav" aria-label="Main">
        {confidenceRating !== 0 && (
          <Button
            customButton={custombutton}
            confidenceRating={confidenceRating}
            onSanityButtonClicked={onSanityButtonClicked}
          />
        )}
      </BottomContainer>
    </PageGridStyle>
  );
};

export default WithFadeIn(WithFocus(RatingPage));
