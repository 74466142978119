import React, { FunctionComponent, useEffect, useState } from 'react';
import { WithFocus } from '../../components';
import WithFadeIn from '../../components/WithFadeIn';
import { useSanityCourse } from '../../context';
import { sanityFunctions } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import { Button } from '../Templates/InfoPageTypes';
import { SelectionTemplate } from '../types/courseTypes';
import SelectionPageTemplate from './SelectionPageTemplate';

type PageProps = {
  template?: SelectionTemplate;
};

const DynamicSelectionPage: FunctionComponent<PageProps> = () => {
  const sanityData = useSanityCourse();

  const navigationContext = useNavigation();
  const [currentPage, setCurrentPage] = useState<SelectionTemplate>({
    buttons: [],
    dropdownMenuItems: [],
    dropdownMenuLabel: '',
    dropdownMenuPlaceholder: '',
    header: '',
    showTimeLeftBar: false,
    utmCampaignQueryString: false,
    queryStringType: 'utm_campaign',
  });
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  useEffect(() => {
    const tempPage = sanityData?.getCurrentPage().selectionTemplate;
    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
      setButtons(
        sanityFunctions.createButtonsFromSanityButtons(
          tempPage?.buttons,
          navigationContext,
        ),
      );
    }
  }, []);

  return (
    <SelectionPageTemplate
      buttons={buttons ?? []}
      dropdownMenuItems={currentPage?.dropdownMenuItems ?? []}
      dropdownMenuLabel={currentPage?.dropdownMenuLabel ?? ''}
      dropdownMenuPlaceholder={currentPage?.dropdownMenuPlaceholder ?? ''}
      pageHeader={currentPage?.header ?? ''}
      showTimeLeftBar={currentPage?.showTimeLeftBar}
      queryStringType={currentPage?.queryStringType}
    />
  );
};

export default WithFadeIn(WithFocus(DynamicSelectionPage));
