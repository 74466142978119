import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { focusElementWithTabIndexMinusOne } from '../../components/UseFocus';
import constants from '../../constants/Constants';
import { useTheme } from '../../context';

type OverlayProps = {
  showCounter: boolean;
  wait?: number;
  duration?: number;
  isVisible?: boolean;
  children?: ReactNode;
  onTimeoutCallback?: () => void;
};
const { pageMargin } = constants;

const ModalBackground = styled.div`
  background: rgba(255, 255, 255, 0.96);
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: normal;
`;

const ModalText = styled.p<{ font?: string }>`
  color: ${(props) => props.theme.colors.neutral_800};
  font-family: ${(props) => props.font};
  font-size: 2.6rem;
  font-style: normal;
  line-height: 120%;
`;
const TextAligner = styled.div`
  text-align: center;
  margin-left: ${pageMargin.SMALL}px;
  margin-right: ${pageMargin.SMALL}px;
`;

const ModalCounter = styled.p`
  font-size: 7.2rem;
  font-style: bold;
  text-align: ${constants.textAlignment.CENTER};
  line-height: 140%;
`;

const GridOverlay: React.FunctionComponent<OverlayProps> = ({
  children,
  duration = 0,
  isVisible = true,
  showCounter = false,
  wait = 0,
}: OverlayProps) => {
  const [counter, setCounter] = useState(duration);
  const [waitCounter, setWaitCounter] = useState(wait);
  const [visibility, setVisibility] = useState(isVisible);
  const timerRef = useRef<number>();
  const waitRef = useRef<number>();
  const decreaseCounterTimer = () => {
    setCounter((prevCount) => prevCount - 1);
  };
  const decreaseWaitTimer = () => {
    setWaitCounter((prevCount) => prevCount - 1);
  };
  useEffect(() => {
    if (counter < 1) {
      clearInterval(timerRef.current);
      setVisibility(false);
    }
  }, [counter]);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (waitCounter < 1) {
      clearInterval(waitRef.current);
      setVisibility(true);
      timerRef.current = window.setInterval(decreaseCounterTimer, 1000);
      return () => clearInterval(timerRef.current);
    }
  }, [waitCounter]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    focusElementWithTabIndexMinusOne();
    if (wait > 0) {
      setVisibility(false);
      waitRef.current = window.setInterval(decreaseWaitTimer, 1000);
      return () => clearInterval(waitRef.current);
    }
  }, []);

  const themeContext = useTheme();
  const { primaryFont } = themeContext.getFonts();

  if (visibility && isVisible) {
    return (
      <ModalBackground>
        <TextAligner>
          <ModalText font={primaryFont} tabIndex={-1} aria-live="polite">
            {children}
          </ModalText>
          {showCounter ? (
            <ModalText font={primaryFont}>
              <br />
              <ModalCounter aria-live="polite">{counter}</ModalCounter>
            </ModalText>
          ) : null}
        </TextAligner>
      </ModalBackground>
    );
  }
  return null;
};
export default GridOverlay;
